<div class="grid">
    <div class="category-tree">
        <app-category-tree></app-category-tree>
    </div>
    <div class="outlet">
        <mat-tab-group [(selectedIndex)]="selectedTab" dynamicHeight  color="primary" animationDuration="500ms">

            <mat-tab label="Parts">
        <app-parts-table-view></app-parts-table-view>
            </mat-tab>
            <mat-tab label="Locations">
                <app-locations-table-view></app-locations-table-view>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

