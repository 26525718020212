import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {OperatingSystem, Provider, Server, ServerStandort} from "../../models/ai-infra-models";
import {urlBaseAiInfra} from "../config";

@Injectable({
  providedIn: 'root'
})
export class AiInfraGetService {

  constructor(private http: HttpClient) { }

  public getAllServers(): Observable<Server[]> {
    return this.http.get<Server[]>(
        `${urlBaseAiInfra}/server/get/all`);
  }

  public getAllProvider(): Observable<Provider[]> {
    return this.http.get<Provider[]>(
        `${urlBaseAiInfra}/provider/get/all`);
  }

  public getAllServerStandorte(): Observable<ServerStandort[]> {
    return this.http.get<ServerStandort[]>(
        `${urlBaseAiInfra}/serverstandort/get/all`);
  }

  public getAllOperatingSystems(): Observable<OperatingSystem[]> {
    return this.http.get<OperatingSystem[]>(
        `${urlBaseAiInfra}/operatingsystem/get/all`);
  }
}
