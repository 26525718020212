<div class="grid">
<div (click)="goBack()" class="back-link">
        <mat-icon class="back-icon">first_page</mat-icon>
</div>
        <div class="outer-content-mapper" style="margin-right: 2%">
        <mat-tab-group [(selectedIndex)]="selectedTab" dynamicHeight  color="primary" animationDuration="500ms">

                <mat-tab label="Account-Mapping">
                        <div class="spinner-overlay" *ngIf="spinnerWithoutBackdrop">
                                <app-progress-spinner></app-progress-spinner>
                        </div>

                        <div class="userinfo-container" *ngIf="!spinnerWithoutBackdrop">
                                <div class="center-tree">
                                        <div class="ul-tree horizontal">
                                                <ul>
                                                        <li>
                                                                <p>{{accountHolder}}</p>
                                                                <ul>
                                                                        <li *ngIf="websites.length > 0"><p>Websites</p>
                                                                                <ul>
                                                                                        <li *ngFor="let website of websites" [class.shared]="checkForShared(website.FK_AccountID)">
                                                                                                <p [class.shared]="checkForShared(website.FK_AccountID)">
                                                                                                        <button mat-button class="end-node" color="warn" [matMenuTriggerFor]="menu">
                                                                                                                <span [class.shared]="checkForShared(website.FK_AccountID)">{{website.WebsiteName}}</span></button>
                                                                                                        <mat-menu #menu="matMenu">
                                                                                                                <button (click)="sendToEdit(website.FK_AccountID)" mat-menu-item >Bearbeiten</button>
                                                                                                                <button (click)="deleteAccount(website.FK_AccountID)"  mat-menu-item >Löschen</button>
                                                                                                        </mat-menu>
                                                                                                </p>
                                                                                        </li>
                                                                                </ul>
                                                                        </li>
                                                                        <li *ngIf="applications.length > 0"><p>Applications</p>
                                                                                <ul>
                                                                                        <li *ngFor="let application of applications" [class.shared]="checkForShared(application.FK_AccountID)">
                                                                                                <p [class.shared]="checkForShared(application.FK_AccountID)">
                                                                                                        <button mat-button class="end-node" color="warn" [matMenuTriggerFor]="menu">
                                                                                                                <span [class.shared]="checkForShared(application.FK_AccountID)">{{application.ApplicationName}}</span></button>
                                                                                                        <mat-menu #menu="matMenu">
                                                                                                                <button (click)="sendToEdit(application.FK_AccountID)" mat-menu-item >Bearbeiten</button>
                                                                                                                <button (click)="deleteAccount(application.FK_AccountID)"  mat-menu-item >Löschen</button>
                                                                                                        </mat-menu>
                                                                                                </p>
                                                                                        </li>
                                                                                </ul>
                                                                        </li>
                                                                        <li *ngIf="servers.length > 0"><p>Server</p>
                                                                                <ul>
                                                                                        <li *ngFor="let server of servers" [class.shared]="checkForShared(server.FK_AccountID)">
                                                                                                <p [class.shared]="checkForShared(server.FK_AccountID)">
                                                                                                        <button mat-button class="end-node" color="warn" [matMenuTriggerFor]="menu">
                                                                                                                <span [class.shared]="checkForShared(server.FK_AccountID)">{{server.ServerName}}</span></button>
                                                                                                        <mat-menu #menu="matMenu">
                                                                                                                <button (click)="sendToEdit(server.FK_AccountID)" mat-menu-item >Bearbeiten</button>
                                                                                                                <button (click)="deleteAccount(server.FK_AccountID)"  mat-menu-item >Löschen</button>
                                                                                                        </mat-menu>
                                                                                                </p>
                                                                                        </li>
                                                                                </ul>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                </ul>
                                        </div>
                                </div>
                        </div>
                </mat-tab>
                <mat-tab label="Bearbeiten"><app-user-edit></app-user-edit></mat-tab>
        </mat-tab-group>
        </div>
</div>