<div class="container">
    <div [routerLink]="'/ai-infra/server'" class='split col-xs-12 col-sm-6 server-side'>
        <div>
            <div class='text-content'>
                <div class='big'>Server</div>
            </div>
        </div>
    </div>

    <div [routerLink]="'/ai-infra/website'" class='split col-xs-12 col-sm-6 website-side'>
        <div>
            <div class='text-content'>
                <div class='big'>Websites</div>
            </div>
        </div>
    </div>

    <div class='split-line'>
        <img src='assets/icons/ai_logo.ico' alt="AI">
    </div>
</div>