import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";

import {urlBaseAiInfra} from "../config";

@Injectable({
  providedIn: 'root'
})
export class AiInfraDeleteService {

  constructor(private http: HttpClient) {}

  public deleteOS(osid: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBaseAiInfra}/operatingsystem/delete/${osid}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteProvider(id: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBaseAiInfra}/provider/delete/${id}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteStandort(id: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBaseAiInfra}/serverstandort/delete/${id}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }
}
