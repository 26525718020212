import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {urlBaseAiParts} from "./config";

@Injectable({
  providedIn: 'root'
})
export class AiPartsDeleteService {

  constructor(private http: HttpClient) {}

  public deletePart(partID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBaseAiParts}/part/delete/${partID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteStorageLocation(locID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBaseAiParts}/storagelocation/delete/${locID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }
}
