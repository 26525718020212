import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {KeycloakGuard} from "./keycloak.guard";

import {HomeComponent} from "./home/home.component";


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [KeycloakGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
