import { Injectable } from '@angular/core';
import {
  AccountHolder,
  AccountStatus,
  AccountType,
  Application, ApplicationType,
  OperatingSystem,
  Server,
  Website
} from "../models/account-models";

@Injectable({
  providedIn: 'root'
})
export class InterfaceTranslationService {


  public translateAccountHolderID(array: AccountHolder[], identifier: string): number {
    let candidate = 1;
    array.forEach((accH) => {
      if(accH.Identifier === identifier){
        candidate = accH.AccountHolderID;
      }
    });
    return candidate;
  }

  public translateAccountTypeID(array: AccountType[], type: string): number {
    let candidate = 1;
    array.forEach((accT) => {
      if(accT.AccountType === type){
        candidate = accT.AccountTypeID;
      }
    });
    return candidate;
  }

  public translateAccountStatusID(array: AccountStatus[], status: string): number {
    let candidate = 1;
    array.forEach((accS) => {
      if(accS.AccountStatus === status){
        candidate = accS.AccountStatusID;
      }
    });
    return candidate;
  }

  public translateServerID(array: Server[], serverName: string): number {
    let candidate = 1;
    array.forEach((s) => {
      if(s.ServerName === serverName){
        candidate = s.ServerID;
      }
    });
    return candidate;
  }

  public translateWebsiteID(array: Website[], websiteName: string): number {
    let candidate = 1;
    array.forEach((w) => {
      if(w.WebsiteName === websiteName){
        candidate = w.WebsiteID;
      }
    });
    return candidate;
  }

  public translateApplicationID(array: Application[], applicationName: string): number {
    let candidate = 1;
    array.forEach((app) => {
      if(app.ApplicationName === applicationName){
        candidate = app.ApplicationID;
      }
    });
    return candidate;
  }

  public translateOSID(array: OperatingSystem[], osName: string): number {
    let candidate = 1;
    array.forEach((os) => {
      if(os.Name === osName){
        candidate = os.OSID;
      }
    });
    return candidate;
  }

  public translateApplicationTypeID(array: ApplicationType[], appType: string): number {
    let candidate = 1;
    array.forEach((application) => {
      if(application.ApplicationType === appType){
        candidate = application.ApplicationTypeID;
      }
    });
    return candidate;
  }
}
