import {Component, OnInit} from '@angular/core';
import {Server} from "../../models/ai-infra-models";
import {DataSharingService} from "../../services/data-sharing.service";
import {ActivatedRoute} from "@angular/router";
import {defaultServer} from "../../models/default-models";

@Component({
  selector: 'app-server-detail',
  templateUrl: './server-detail.component.html',
  styleUrls: ['./server-detail.component.scss']
})
export class ServerDetailComponent implements OnInit {
  selectedServer: Server = defaultServer();
  navMap: boolean[] = [true, false, false, false];

  constructor(
      private dataShare: DataSharingService,
      private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.dataShare.loadAllServers();
    this.dataShare.allServersObs.subscribe(() => {
      this.route.params.subscribe(params => {
        if (params['hostname'] !== '') {
          this.selectedServer = this.dataShare.getServerByHostname(params['hostname']);
          this.dataShare.setSelectedEditServer(this.selectedServer);
        } else {
          const url = window.location.href;
          const parts = url.split('/');
          const lastPart = parts[parts.length - 1];
          this.selectedServer = this.dataShare.getServerByHostname(lastPart);
        }
      });
    });
  }

  public setActiveClass(route: string): void {
    for (let i = 0; i < this.navMap.length; i++) {
      this.navMap[i] = false;
    }
    switch (route) {
      case 'overview':
        this.navMap[0] = true
        break;
      case 'graphs':
        this.navMap[1] = true
        break;
      case 'backups':
        this.navMap[2] = true
        break;
      case 'logs':
        this.navMap[3] = true
        break;
      default:
        this.navMap[0] = true
        break;
    }
  }

  private getRouteParam(): string {
    const url = window.location.href;
    const parts = url.split('/');
    return parts[parts.length - 1];
  }
}