import {FlatNode, Part, PartCategory, StorageLocation, StorageLocationCategory} from "./ai-parts-models";

export function defaultPart(): Part {
    return {
        id: 0,
        category_id: 1,
        category: "",
        name: "",
        description: "",
        stockLevel: 0,
        createDate: "",
        storageLocation_id: 0,
        location: ""
    }
}

export function defaultParts(): Part[] {
    return [{
        id: 0,
        category_id: 1,
        category: "",
        name: "",
        description: "",
        stockLevel: 0,
        createDate: "",
        storageLocation_id: 0,
        location: ""
    }]
}

export function defaultPartCategorys(): PartCategory[] {
    return [{
        id: 0,
        name: "",
        categoryPath: "{}",
        categoryString: ""
    }]
}

export function defaultStorageLocations(): StorageLocation[] {
    return [{
        id: 0,
        category_id: 0,
        name: ""
    }]
}

export function defaultFlatNode(): FlatNode {
    return {
        name: "",
        expandable: false,
        level: 0
    }
}

export function defaultStorageLocation(): StorageLocation {
    return {
        id: 0,
        name: "",
        category_id: 0
    }
}

export function defaultStorageLocationCategorys(): StorageLocationCategory[] {
    return [{
        id: 0,
        name: ""
    }]
}