import { Component } from '@angular/core';

@Component({
  selector: 'app-logs',
  templateUrl: './server-logs.component.html',
  styleUrls: ['./server-logs.component.scss']
})
export class ServerLogsComponent {

}
