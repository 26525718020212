import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Part, PartCategory, StorageLocation} from "../../models/ai-parts-models";
import {urlBaseAiParts} from "./config";

@Injectable({
  providedIn: 'root'
})
export class AiPartsPostService {

  constructor(private http: HttpClient) {}

  public updatePart(part: Part): Observable<HttpResponse<Part>>{
    return this.http.post<Part>(
        `${urlBaseAiParts}/part/update`, JSON.stringify(part),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public updateStorageLocation(loc: StorageLocation): Observable<HttpResponse<StorageLocation>>{
    return this.http.post<StorageLocation>(
        `${urlBaseAiParts}/storagelocation/update`, JSON.stringify(loc),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initPart(part: Part): Observable<HttpResponse<Part>>{
    return this.http.post<Part>(
        `${urlBaseAiParts}/part/init`, JSON.stringify(part),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initStorageLocation(loc: StorageLocation): Observable<HttpResponse<StorageLocation>>{
    return this.http.post<StorageLocation>(
        `${urlBaseAiParts}/storagelocation/init`, JSON.stringify(loc),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initPartCategory(partCategory: PartCategory): Observable<HttpResponse<PartCategory>>{
    return this.http.post<PartCategory>(
        `${urlBaseAiParts}/partcategory/init`, JSON.stringify(partCategory),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }
}
