import {Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {
    AccountComplete, AccountHolder,
    ApplicationEnhanced,
    ServerEnhanced,
    WebsiteEnhanced
} from "../models/account-models";
import {AccountApiGetService} from "../services/api/account-api-get.service";
import {DataSharingService} from "../services/data-sharing.service";
import {ActivatedRoute} from "@angular/router";
import {InterfaceTranslationService} from "../services/interface-translation.service";
import {SnackbarService} from "../../auxiliarys/services/snackbar.service";
import {AccountApiDeleteService} from "../services/api/account-api-delete.service";


@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})

export class UserInfoComponent implements OnInit{
    accounts: AccountComplete[] = [];
    servers: ServerEnhanced[] = [];
    websites: WebsiteEnhanced[] = [];
    applications: ApplicationEnhanced[] = [];
    allAccountHolders: AccountHolder[] = [];
    selectedTab= 1;
    accountHolder = "";
    public wasSelected = false;
    spinnerWithoutBackdrop = false;
    sharedMap: Map<number, boolean> = new Map<number, boolean>();

    constructor(
        private accountAPI: AccountApiGetService,
        private _location: Location,
        private dataSharingService: DataSharingService,
        private route: ActivatedRoute,
        private translator: InterfaceTranslationService,
        private snackbar: SnackbarService,
        private accountDeleteAPI: AccountApiDeleteService
    ) {}

    private getAllAccountHolderAccounts(aid: number){
        const resp = this.accountAPI.getAllAccountHolderAccounts(aid);
        resp.subscribe(apiResponse=> {
            this.accounts=apiResponse as AccountComplete[];
            this.accounts.forEach((tmp) => {
                if(tmp.Server.ServerName != '') {
                    const se = {} as ServerEnhanced;
                    se.FK_AccountID = tmp.AccountID;
                    se.ServerName = tmp.Server.ServerName;
                    se.ServerIP = tmp.Server.ServerIP;
                    se.ServerID = tmp.Server.ServerID;
                    se.OS = tmp.Server.OS.Name;
                    this.servers.push(se);
                }
                if(tmp.Website.WebsiteName != '') {
                    const we = {} as WebsiteEnhanced;
                    we.FK_AccountID = tmp.AccountID;
                    we.WebsiteName = tmp.Website.WebsiteName;
                    we.WebsiteID = tmp.Website.WebsiteID;
                    we.WebsiteURL = tmp.Website.WebsiteURL;
                    this.websites.push(we);
                }
                if(tmp.Application.ApplicationName != '') {
                    const app = {} as ApplicationEnhanced;
                    app.FK_AccountID = tmp.AccountID;
                    app.ApplicationName = tmp.Application.ApplicationName;
                    app.ApplicationID = tmp.Application.ApplicationID;
                    app.ApplicationType = tmp.Application.ApplicationType.ApplicationType;
                    this.applications.push(app);
                }
                if(tmp.AccountType.AccountType == "Shared Account"){
                    this.sharedMap.set(tmp.AccountID, true);
                }
            });
        });
    }

    ngOnInit(): void {
        this.spinnerWithoutBackdrop = true;
        this.selectedTab = 0;
        this.accountAPI.getAllAccountHolder().subscribe(apiResponse=> {
            this.allAccountHolders = apiResponse as AccountHolder[];
            this.route.params.subscribe(params => {
                const identifier = params['identifier'];
                this.accountHolder = identifier;
                const id = this.translator.translateAccountHolderID(this.allAccountHolders, identifier)
                this.getAllAccountHolderAccounts(id);
                setTimeout(() => {
                    this.spinnerWithoutBackdrop = false;
                }, 900);
            });
        });
    }

    public sendToEdit(accID: number) {
        this.dataSharingService.changeAccountID(accID);
        this.dataSharingService.shareAccounts(this.accounts, accID);
        this.dataSharingService.wasSelectedSource.next(true);
        this.selectedTab = 1;
    }


    public deleteAccount(account_id: number): void {
        this.snackbar.handleServerResonseCode(
            this.accountDeleteAPI.deleteAccount(account_id));
    }

    public goBack() {
    this._location.back();
  }

    public checkForShared(accID: number): boolean {
        return this.sharedMap.has(accID);
    }
}
