import { Injectable } from '@angular/core';
import {
  Account, AccountHolder,
  AccountStatus,
  AccountType,
  Application,
  ApplicationType, OperatingSystem,
  Server, SharedAccount,
  Website
} from "../../models/account-models";
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {urlBase} from "./config";

@Injectable({
  providedIn: 'root'
})
export class AccountApiPostService {

  constructor(private http: HttpClient) {}

  public updateAccount(account: Account): Observable<HttpResponse<Account>>{
    return this.http.post<Account>(
        `${urlBase}/account/update`, JSON.stringify(account),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public updateServer(server: Server): Observable<HttpResponse<Server>>{
    return this.http.post<Server>(
        `${urlBase}/server/update`, JSON.stringify(server),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public updateWebsite(website: Website): Observable<HttpResponse<Website>>{
    return this.http.post<Website>(
        `${urlBase}/website/update`, JSON.stringify(website),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public updateApplication(application: Application): Observable<HttpResponse<Application>>{
    return this.http.post<Application>(
        `${urlBase}/application/update`, JSON.stringify(application),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initServer(server: Server): Observable<HttpResponse<Server>>{
    return this.http.post<Server>(
        `${urlBase}/server/init`, JSON.stringify(server),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initWebsite(website: Website): Observable<HttpResponse<Website>>{
    return this.http.post<Website>(
        `${urlBase}/website/init`, JSON.stringify(website),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initApplication(application: Application): Observable<HttpResponse<Application>>{
    return this.http.post<Application>(
        `${urlBase}/application/init`, JSON.stringify(application),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initAccount(account: Account): Observable<HttpResponse<Account>>{
    return this.http.post<Account>(
        `${urlBase}/account/init`, JSON.stringify(account),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initSharedAccount(account: SharedAccount): Observable<HttpResponse<SharedAccount>>{
    return this.http.post<SharedAccount>(
        `${urlBase}/account/init-shared`, JSON.stringify(account),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initAccountStatus(accountStatus: AccountStatus): Observable<HttpResponse<AccountStatus>>{
    return this.http.post<AccountStatus>(
        `${urlBase}/accountstatus/init`, JSON.stringify(accountStatus),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initAccountType(accountType: AccountType): Observable<HttpResponse<AccountType>>{
    return this.http.post<AccountType>(
        `${urlBase}/accounttype/init`, JSON.stringify(accountType),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initApplicationType(applicationType: ApplicationType): Observable<HttpResponse<ApplicationType>>{
    return this.http.post<ApplicationType>(
        `${urlBase}/applicationtype/init`, JSON.stringify(applicationType),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initOperatingSystem(os: OperatingSystem): Observable<HttpResponse<OperatingSystem>>{
    return this.http.post<OperatingSystem>(
        `${urlBase}/os/init`, JSON.stringify(os),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initAccountHolder(holder: AccountHolder): Observable<HttpResponse<AccountHolder>>{
    return this.http.post<AccountHolder>(
        `${urlBase}/accountholder/init`, JSON.stringify(holder),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }
}
