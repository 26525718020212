import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {OperatingSystem, Provider, Server, ServerStandort} from "../../models/ai-infra-models";
import {
  defaultOperatingSystems, defaultOS, defaultProvider,
  defaultProviders,
  defaultServer,
  defaultServerStandorte,
  defaultServerStandort
} from "../../models/default-models";
import {DataSharingService} from "../../services/data-sharing.service";
import {SnackbarService} from "../../../auxiliarys/services/snackbar.service";
import {AiInfraPostService} from "../../services/api/ai-infra-post.service";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ValidationService} from "../../../auxiliarys/services/validation.service";
import {TranslationService} from "../../services/translation.service";
import {checkServerInputExt} from "./check-functions";
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import {AiInfraDeleteService} from "../../services/api/ai-infra-delete.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../../../auxiliarys/dialog/dialog.component";
import {Observable} from "rxjs";

@Component({
  selector: 'app-server-bottom-sheet',
  templateUrl: './server-bottom-sheet.component.html',
  styleUrls: ['./server-bottom-sheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServerBottomSheetComponent implements OnInit{
  selectedServer: Server = defaultServer();
  selectedOS: OperatingSystem = defaultOS();
  selectedProvider: Provider = defaultProvider();
  selectedStandort: ServerStandort = defaultServerStandort();
  allOperatingsystems: OperatingSystem[] = defaultOperatingSystems();
  allProvider: Provider[] = defaultProviders();
  allServerStandorte: ServerStandort[] = defaultServerStandorte();

  constructor(
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: string,
      private _bottomSheetRef: MatBottomSheetRef<ServerBottomSheetComponent>,
      private dataShare: DataSharingService,
      private snackbar: SnackbarService,
      private aiInfraPostService: AiInfraPostService,
      private aiInfraDeleteService: AiInfraDeleteService,
      public validationService: ValidationService,
      public translationService: TranslationService,
      public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.selectedServer = this.dataShare.getSelectedServer();
    this.allOperatingsystems = this.dataShare.getAllOperatingSystems();
    this.allProvider = this.dataShare.getAllProvider();
    this.allServerStandorte = this.dataShare.getAllServerStandorte();
  }

  private openDialog(diagMsg: string): Observable<boolean> {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {message: diagMsg},
    });
    return dialogRef.afterClosed();
  }

  public checkServerInput(server: Server): boolean {
    return checkServerInputExt(server);
  }

  // ########## Server ########## //

  public initServer(): void {
    this.aiInfraPostService.initServer(this.selectedServer).subscribe(_resp => {
      this.snackbar.handleServerResonseCodeNoObs(_resp.status);
      this.dataShare.loadAllServers();
      this._bottomSheetRef.dismiss();
    });
  }

  // ########## OperatingSystem ########## //

  public initOS(): void {
    this.aiInfraPostService.initOS(this.selectedOS).subscribe(_resp => {
      this.snackbar.handleServerResonseCodeNoObs(_resp.status);
      this.dataShare.loadAllOperatingSystems();
      this._bottomSheetRef.dismiss();
    });
  }

  public updateOS(): void {
    this.aiInfraPostService.updateOS(this.selectedOS).subscribe(_resp => {
      this.snackbar.handleServerResonseCodeNoObs(_resp.status);
      this.dataShare.loadAllOperatingSystems();
      this._bottomSheetRef.dismiss();
    });
  }

  public deleteOS(osid: number): void {
    this.openDialog('The deletion of this operatingsystem will result in the deletion of all associated server')
        .subscribe(_submit => {
          if(_submit) {
            this.aiInfraDeleteService.deleteOS(osid).subscribe(_resp => {
              this.snackbar.handleServerResonseCodeNoObs(_resp.status);
              this.allOperatingsystems = this.popOS(osid);
              this.dataShare.loadAllServers();
            });
          }
        });
    }

  private popOS(osid: number): OperatingSystem[] {
      const array: OperatingSystem[] = [];
      this.allOperatingsystems.forEach(_os => {
        if(_os.OSID !== osid){
          array.push(_os);
        }
      });
      return array;
  }

  // ########## Provider ########## //

  public initProvider(): void {
    this.aiInfraPostService.initProvider(this.selectedProvider).subscribe(_resp => {
      this.snackbar.handleServerResonseCodeNoObs(_resp.status);
      this.dataShare.loadAllProvider();
      this._bottomSheetRef.dismiss();
    });
  }

  public updateProvider(): void {
    this.aiInfraPostService.updateProvider(this.selectedProvider).subscribe(_resp => {
      this.snackbar.handleServerResonseCodeNoObs(_resp.status);
      this.dataShare.loadAllProvider();
      this._bottomSheetRef.dismiss();
    });
  }

  public deleteProvider(id: number): void {
    this.openDialog('The deletion of this provider will result in the deletion of all associated server')
        .subscribe(_submit => {
          if(_submit) {
            this.aiInfraDeleteService.deleteProvider(id).subscribe(_resp => {
              this.snackbar.handleServerResonseCodeNoObs(_resp.status);
              this.dataShare.loadAllServers();
              this.allProvider = this.popProvider(id);
              this._bottomSheetRef.dismiss();
            });
          }
        });
  }

  private popProvider(id: number): Provider[] {
    const array: Provider[] = [];
    this.allProvider.forEach(_provider => {
      if(_provider.ProviderID !== id){
        array.push(_provider);
      }
    });
    return array;
  }

  // ########## ServerStandort ########## //

  public initStandort(): void {
    this.aiInfraPostService.initStandort(this.selectedStandort).subscribe(_resp => {
      this.snackbar.handleServerResonseCodeNoObs(_resp.status);
      this.dataShare.loadAllServerStandorte();
      this._bottomSheetRef.dismiss();
    });
  }

  public updateStandort(): void {
    this.aiInfraPostService.updateStandort(this.selectedStandort).subscribe(_resp => {
      this.snackbar.handleServerResonseCodeNoObs(_resp.status);
      this.dataShare.loadAllServerStandorte();
      this._bottomSheetRef.dismiss();
    });
  }

  public deleteStandort(id: number): void {
    this.openDialog('The deletion of this standort will result in the deletion of all associated server')
        .subscribe(_submit => {
          if(_submit) {
            this.aiInfraDeleteService.deleteStandort(id).subscribe(_resp => {
              this.snackbar.handleServerResonseCodeNoObs(_resp.status);
              this.dataShare.loadAllServers();
              this.allServerStandorte = this.popStandort(id);
          });
        }
    });
  }

  private popStandort(id: number): ServerStandort[] {
    const array: ServerStandort[] = [];
    this.allServerStandorte.forEach(_standort => {
      if(_standort.StandortID !== id){
        array.push(_standort);
      }
    });
    return array;
  }
}
