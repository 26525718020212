export const config = {
  "sharedAccount": "Shared Account",
  "defaultEditAccountValues": {
    "accountStatus": "Active",
    "accountType": "Account"
  },
  "accountAddMapper": {
    "server": 0,
    "website": 1,
    "application": 2
  },
  "accountEditMapper": {
    "server": 0,
    "website": 1,
    "application": 2
  }
}