import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {KeycloakGuard} from "../keycloak.guard";
import {UserInfoComponent} from "./user-info/user-info.component";
import {SelectorComponent} from "./selector/selector.component";

const routes: Routes = [
  {
    path: 'accounts/:identifier',
    component: UserInfoComponent,
    canActivate: [KeycloakGuard],
    data: { roles: ['AI-Accounts', 'AI-Accounts-Read-Only'] }
  },
  {
    path: 'accounts',
    component: SelectorComponent,
    canActivate: [KeycloakGuard],
    data: { roles: ['AI-Accounts', 'AI-Accounts-Read-Only'] }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AccountTrackingRoutingModule { }
