import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {StorageLocation, StorageLocationCategory} from "../../models/ai-parts-models";
import {defaultStorageLocation, defaultStorageLocationCategorys} from "../../models/default-models";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {DataSharingService} from "../../services/data-sharing.service";
import {AiPartsGetService} from "../../services/api/ai-parts-get.service";
import {AiPartsPostService} from "../../services/api/ai-parts-post.service";
import {SnackbarService} from "../../../auxiliarys/services/snackbar.service";
import {AiPartsDeleteService} from "../../services/api/ai-parts-delete.service";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {DialogComponent} from "../../../auxiliarys/dialog/dialog.component";

@Component({
  selector: 'app-locations-bottom-sheet',
  templateUrl: './locations-bottom-sheet.component.html',
  styleUrls: ['./locations-bottom-sheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationsBottomSheetComponent implements OnInit{
  selectedStorageLocation: StorageLocation = defaultStorageLocation();
  newStorageLocationObj: StorageLocation = defaultStorageLocation();
  allStorageLocationCategorys: StorageLocationCategory[] = defaultStorageLocationCategorys();

  constructor(
      private _bottomSheetRef: MatBottomSheetRef<LocationsBottomSheetComponent>,
      private dataShare: DataSharingService,
      private aiPartsGetAPI: AiPartsGetService,
      private aiPartsPostAPI: AiPartsPostService,
      private snackbar: SnackbarService,
      private aiPartsDeleteAPI: AiPartsDeleteService,
      public dialog: MatDialog
  ) {}

  private openDialog(diagMsg: string): Observable<boolean> {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {message: diagMsg},
    });
    return dialogRef.afterClosed();
  }

  public updateStorageLocation(): void {
    this.snackbar.handleServerResonseCode(
        this.aiPartsPostAPI.updateStorageLocation(
            this.selectedStorageLocation));
    this._bottomSheetRef.dismiss();
    this.dataShare.setSelectedStorageLocation(defaultStorageLocation());
  }

  public initStorageLocation(): void {
    this.snackbar.handleServerResonseCode(
        this.aiPartsPostAPI.initStorageLocation(
            this.newStorageLocationObj));
    this._bottomSheetRef.dismiss();
    this.dataShare.setSelectedStorageLocation(defaultStorageLocation());
  }

  public deleteStorageLocation(): void {
    this.openDialog('The deletion of this location will result in the deletion of all associated parts')
        .subscribe(_submit => {
          if (_submit) {
            this.aiPartsDeleteAPI.deleteStorageLocation(this.selectedStorageLocation.id).subscribe(_resp => {
              this.snackbar.handleServerResonseCodeNoObs(_resp.status);
              this._bottomSheetRef.dismiss();
              this.dataShare.setSelectedStorageLocation(defaultStorageLocation());
            });
          }
        });
  }

  public mapStorageLocationCategory_id(id: number): void {
    this.selectedStorageLocation.category_id = id;
    if(this.selectedStorageLocation.id === 0) {
      this.newStorageLocationObj.category_id = id;
    }

  }

  public resolveStorageLocationCategoryID(id: number): string {
    let candidate = "";
    this.allStorageLocationCategorys.forEach(locCat => {
      if(locCat.id == id){
        candidate = locCat.name;
      }
    });
    return candidate;
  }

  ngOnInit(): void {
    this.allStorageLocationCategorys = this.dataShare.getallStorageLocationCategorys();
    this.selectedStorageLocation = this.dataShare.getSelectedStorageLocation();
  }
}
