<div class="navbar">
    <mat-toolbar >
        <button mat-icon-button (click)="drawer.toggle()" >
            <mat-icon>menu</mat-icon>
        </button>
        <div class="logo">
            <a href="/" class="logo-mini">
                <div class="logo-img">
                    <img src="../assets/images/ai_fullwhite.png"  alt="AI-Gruppe"/>
                </div>
            </a>
        </div>
        <span class="spacer"></span>
        <button class="user-dropdown " mat-button [matMenuTriggerFor]="userMenu"><span class="username-options">{{getUsername()}}</span></button>
        <mat-menu class="themed-menu" #userMenu="matMenu" yPosition="below" >
            <button mat-menu-item>Einstellungen</button>
            <button mat-menu-item (click)="logout()">Abmelden</button>
        </mat-menu>
    </mat-toolbar>
</div>



<div class="app-component-sidenav">
    <mat-drawer-container class="mat-drawer-container" hasBackdrop="false">
        <mat-drawer-content>
            <router-outlet></router-outlet>
            <div class="app-component-drawer-inner-content">
                <main class="app-component-drawer-body-content">

                </main>

            </div>
        </mat-drawer-content>
        <mat-drawer class="mat-drawer-size mat-drawer-frame" #drawer mode="push" opened="false">
            <ul class="css-19h5az5" *ngFor="let module of modules">
                <li [routerLink]="module.routerlink"  class="css-k008qs">
                    <div class="css-18gmj6g">
                        <a class="css-tkv12j"></a>
                        <div class="css-v2kfba">
                            <div class="css-v2kfba">
                                <div class="css-1aj633s">
                                    <div class="css-1dw09zn">
                                        <mat-icon *ngIf="validationService.checkUserRole(module.roles)" class="css-1m01c8l">{{module.icon}}</mat-icon>
                                        <mat-icon *ngIf="!validationService.checkUserRole(module.roles)" class="css-1m01c8l">lock</mat-icon>
                                    </div>
                                    <span *ngIf="validationService.checkUserRole(module.roles)" class="css-86eryg">{{module.name}}</span>
                                    <span *ngIf="!validationService.checkUserRole(module.roles)" class="css-86eryg">Locked</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </mat-drawer>
        <div class="inner-content">
            <button type="button" mat-button (click)="drawer.toggle()">
                Toggle sidenav
            </button>
        </div>
    </mat-drawer-container>
</div>