import {Server} from "../../models/ai-infra-models";


export function checkServerInputExt(entity: Server): boolean {
    return (
        entity.IPv4 == '' ||
        entity.IPv6 == '' ||
        entity.Hostname == '' ||
        entity.FK_OSID == 0 ||
        entity.FK_ProviderID == 0 ||
        entity.FK_StandortID == 0
    );
}