import { Component } from '@angular/core';

@Component({
  selector: 'app-graphs',
  templateUrl: './server-graphs.component.html',
  styleUrls: ['./server-graphs.component.scss']
})
export class ServerGraphsComponent {

}
