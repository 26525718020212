import {Component, ViewEncapsulation} from '@angular/core';
import {InfraboardModules} from "./app-data/infraboard-modules";
import infraboardModules from "./app-data/infraboard-modules.json";
import {KeycloakService} from "keycloak-angular";
import {ValidationService} from "./auxiliarys/services/validation.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'Infraboard';

  modules: InfraboardModules[] = infraboardModules;

  constructor(
      private keycloakService: KeycloakService,
      public validationService: ValidationService
  ) { }


  public getUsername(): string{
    return this.keycloakService.getUsername().split("@")[0];
  }

  public logout() {
    this.keycloakService.logout();
  }
}