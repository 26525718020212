import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {KeycloakGuard} from "../keycloak.guard";
import {AiPartsComponent} from "./ai-parts.component";

const routes: Routes = [
  {
    path: 'ai-parts',
    component: AiPartsComponent,
    canActivate: [KeycloakGuard],
    data: { roles: ['AI-Parts', 'AI-Parts-Read-Only'] }
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AiPartsRoutingModule { }
