import { Component } from '@angular/core';

@Component({
  selector: 'app-backups',
  templateUrl: './server-backups.component.html',
  styleUrls: ['./server-backups.component.scss']
})
export class ServerBackupsComponent {

}
