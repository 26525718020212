import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from "./material.module";
import { AccountTrackingComponent } from "./account-tracking/account-tracking.component";
import { HomeComponent } from './home/home.component';
import { AccountTrackingModule } from "./account-tracking/account-tracking.module";
import { HttpClientModule } from "@angular/common/http";
import { SnackbarSuccessfullComponent } from './auxiliarys/snackbar-successfull/snackbar-successfull.component';
import { AiPartsComponent } from './ai-parts/ai-parts.component';
import { AiPartsModule } from "./ai-parts/ai-parts.module";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {MatTabsModule} from "@angular/material/tabs";
import { AiInfraComponent } from './ai-infra/ai-infra.component';
import {AiInfraModule} from "./ai-infra/ai-infra.module";
import { DialogComponent } from './auxiliarys/dialog/dialog.component';
import {MatDialogModule} from "@angular/material/dialog";

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
      keycloak.init({
        config: {
          url: 'https://keycloak.gruppe.ai/auth',
          realm: 'AI-Gruppe',
          clientId: 'Infraboard'
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
              window.location.origin + '/assets/silent-check-sso.html'
        },
          loadUserProfileAtStartUp: true
      });
}

@NgModule({
    declarations: [
        AppComponent,
        AccountTrackingComponent,
        HomeComponent,
        SnackbarSuccessfullComponent,
        AiPartsComponent,
        AiInfraComponent,
        DialogComponent

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        KeycloakAngularModule,
        AccountTrackingModule,
        HttpClientModule,
        AiPartsModule,
        MatTabsModule,
        AiInfraModule,
        MatDialogModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
