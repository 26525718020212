<app-top-down-menu></app-top-down-menu>
<div class="table-back">
<table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

    <ng-container matColumnDef="Hostname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="Hostname" sortActionDescription="Sort by Hostname">Hostname</th>
        <td mat-cell *matCellDef="let element"> <span class="api-circle-fail">{{element.Hostname}}</span> </td>
<!--        api-circle-fail api-circle-success api-circle-pending  je nachdem, ob die API erreicht wurde-->
    </ng-container>


    <ng-container matColumnDef="IPv4">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="IPv4" sortActionDescription="Sort by IPV4">IPv4</th>
        <td mat-cell *matCellDef="let element"> <div>{{element.IPv4}}</div> </td>
    </ng-container>

    <ng-container matColumnDef="Location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="Location" sortActionDescription="Sort by IPV4">Location</th>
        <td mat-cell *matCellDef="let element">

            <span style="display: inline-flex; align-items: center; margin-top: 8px">
  <svg *ngIf="element.Standort.ISO3166 === 'DE'"  width="22" height="22" viewBox="0 0 100 100" style="margin-right: 5px; margin-top: 10px;">
                <path fill="#FFF351" d="M0 33.3333333h83V50H0z"></path>
                <path fill="#F36565" d="M0 16.6666667h83v16.6666667H0z"></path>
                <path fill="#4B4040" d="M0 0h83v16.6666667H0z"></path>
  </svg>
 {{element.Standort.Standort}}
</span>

        </td>
    </ng-container>

    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="" sortActionDescription=""></th>
        <td mat-cell *matCellDef="let element">
            <div class="options-row">
                    <mat-icon [routerLink]="element.Hostname + '/overview'" style="font-size:22px">settings</mat-icon>
                    <mat-icon style="font-size:22px">delete</mat-icon>
            </div> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
</div>
<div class="page-container">
    <mat-paginator aria-label="Select page of users"></mat-paginator>
</div>
