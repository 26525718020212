import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {SnackbarInfo} from "./snackbar-info";

@Component({
  selector: 'app-snackbar-successfull',
  templateUrl: './snackbar-successfull.component.html',
  styleUrls: ['./snackbar-successfull.component.scss']
})
export class SnackbarSuccessfullComponent  {
  snackbarInfo: SnackbarInfo = {
    Color: "#76be94",
    Message: "Operation Failed",
    Success: false,
    Icon: "info",
    PanelClass: "app-notification-error"
  }
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarInfo) {
    this.snackbarInfo = data;
  }
}
