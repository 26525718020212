import { Injectable } from '@angular/core';
import {AiInfraGetService} from "./api/ai-infra-get.service";
import {BehaviorSubject, Observable} from "rxjs";
import {
  defaultServers,
  defaultProviders,
  defaultServerStandorte,
  defaultOperatingSystems, defaultServer,
  defaultOS
} from "../models/default-models";
import {Server, Provider, ServerStandort, OperatingSystem} from "../models/ai-infra-models";


@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private tableFilterValueSource = new BehaviorSubject<string>('');
  private allServersSource = new BehaviorSubject<Server[]>(defaultServers());
  private allProvidereSource = new BehaviorSubject<Provider[]>(defaultProviders());
  private allServerStandorteSource = new BehaviorSubject<ServerStandort[]>(defaultServerStandorte());
  private allOperatingSystemsSource = new BehaviorSubject<OperatingSystem[]>(defaultOperatingSystems());
  private selectedServerSource = new BehaviorSubject<Server>(defaultServer());
  private selectedOSSource = new BehaviorSubject(defaultOS())
  private selectedEditServerSource = new BehaviorSubject<Server>(defaultServer());

  public tableFilterValueObs = this.tableFilterValueSource.asObservable();
  public allServersObs = this.allServersSource.asObservable();
  public allProvidereObs = this.allProvidereSource.asObservable();
  public allServerStandorteObs = this.allServerStandorteSource.asObservable();
  public allOperatingSystemsObs = this.allOperatingSystemsSource.asObservable();
  public selectedServerObs = this.selectedServerSource.asObservable();
  public selectedOSObs = this.selectedOSSource.asObservable();
  public selectedEditServerObs = this.selectedEditServerSource.asObservable();

  private allServers = defaultServers();
  private allProvider = defaultProviders();
  private allServerStandorte = defaultServerStandorte();
  private allOperatingSystems = defaultOperatingSystems();
  private selectedServer = defaultServer();
  private selectedOS = defaultOS();
  private selectedEditServer = defaultServer();

  constructor(
      private aiInfraGetService: AiInfraGetService
  ) {
    this.allServersObs.subscribe(_servers => {
      this.allServers = _servers;
    });
    this.allProvidereObs.subscribe(_provider => {
      this.allProvider = _provider;
    });
    this.allServerStandorteObs.subscribe(_standorte => {
      this.allServerStandorte = _standorte;
    });
    this.allOperatingSystemsObs.subscribe(_os => {
      this.allOperatingSystems = _os;
    });
    this.selectedServerObs.subscribe(_server => {
      this.selectedServer = _server;
    });
    this.selectedOSObs.subscribe(_os => {
      this.selectedOS = _os;
    });
    this.selectedEditServerObs.subscribe(_server => {
      this.selectedEditServer = _server;
    });
  }

  public setTableFilterValue(value: string): void {
    this.tableFilterValueSource.next(value);
  }


  /* ########## Server ########## */
  public loadAllServers(): Observable<Server[]> {
    const resp = this.aiInfraGetService.getAllServers();
    resp.subscribe(servers => {
      this.setAllServers(servers);
    });
    return resp;
  }

  public getAllServers(): Server[] {
    return this.allServers;
  }

  public setAllServers(server: Server[]): void {
    this.allServersSource.next(server);
  }

  public getSelectedServer(): Server {
    return this.selectedServer;
  }

  public getServerByHostname(hostname: string): Server {
    let candidate: Server = defaultServer();
    this.allServers.forEach(_server => {
      if(_server.Hostname === hostname){
        candidate = _server;
      }
    });
    return candidate;
  }

  public setSelectedServer(server: Server): void {
    this.selectedServerSource.next(server);
  }

  /* ########## Provider ########## */
  public loadAllProvider(): Observable<Provider[]> {
     const resp = this.aiInfraGetService.getAllProvider();
     resp.subscribe(provider => {
      this.setAllProvider(provider);
    });
     return resp;
  }

  public getAllProvider(): Provider[] {
    return this.allProvider;
  }

  public setAllProvider(standorte: Provider[]): void {
    this.allProvidereSource.next(standorte);
  }

  /* ########## ServerStandorte ########## */
  public loadAllServerStandorte(): Observable<ServerStandort[]> {
    const resp = this.aiInfraGetService.getAllServerStandorte()
        resp.subscribe(_standorte => {
      this.setAllServerStandorte(_standorte);
    });
    return resp;
  }

  public getAllServerStandorte(): ServerStandort[] {
    return this.allServerStandorte;
  }

  public setAllServerStandorte(standorte: ServerStandort[]): void {
    this.allServerStandorteSource.next(standorte);
  }

  /* ########## OperatingSystems ########## */
  public loadAllOperatingSystems(): Observable<OperatingSystem[]> {
     const resp = this.aiInfraGetService.getAllOperatingSystems()
         resp.subscribe(_os => {
      this.setAllOperatingSystems(_os);
    });
     return resp;
  }

  public getAllOperatingSystems(): OperatingSystem[] {
    return this.allOperatingSystems;
  }

  public setAllOperatingSystems(os: OperatingSystem[]): void {
    this.allOperatingSystemsSource.next(os);
  }

  public getSelectedEditServer(): Server {
    return  this.selectedEditServer;
  }

  public setSelectedEditServer(server: Server): void {
    this.selectedEditServerSource.next(server);
  }


}
