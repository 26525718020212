import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {Part, PartCategory, StorageLocation} from "../../models/ai-parts-models";
import {defaultFlatNode, defaultPart, defaultPartCategorys, defaultStorageLocations} from "../../models/default-models";
import {DataSharingService} from "../../services/data-sharing.service";
import {AiPartsGetService} from "../../services/api/ai-parts-get.service";
import {SnackbarService} from "../../../auxiliarys/services/snackbar.service";
import {AiPartsPostService} from "../../services/api/ai-parts-post.service";
import {AiPartsDeleteService} from "../../services/api/ai-parts-delete.service";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {DialogComponent} from "../../../auxiliarys/dialog/dialog.component";


@Component({
  selector: 'app-parts-bottom-sheet',
  templateUrl: './parts-bottom-sheet.component.html',
  styleUrls: ['./parts-bottom-sheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PartsBottomSheetComponent implements OnInit {
  public selectedPart: Part = defaultPart();
  public allCategorys: PartCategory[] = defaultPartCategorys();
  public allStorageLocations: StorageLocation[] = defaultStorageLocations();
  public groupedCategorys!: string[][];
  public category = false;
  private headCategoryNode = defaultFlatNode();
  public newCategory = "";
  public categoryPath = "";
  private allCategoryStrings: string[] = [];

  constructor(
      private _bottomSheetRef: MatBottomSheetRef<PartsBottomSheetComponent>,
      private dataShare: DataSharingService,
      private aiPartsGetAPI: AiPartsGetService,
      private aiPartsPostAPI: AiPartsPostService,
      private snackbar: SnackbarService,
      private aiPartsDeleteAPI: AiPartsDeleteService,
      public dialog: MatDialog
  ) {
  }


  ngOnInit(): void {
    this.headCategoryNode = this.dataShare.getHeadCategoryNode();
    this.dataShare.partCategorysObs.subscribe(cats => {
      cats.forEach(cat => {
        this.allCategoryStrings.push(cat.categoryString);
      })
    });
    if (this.headCategoryNode.name == '') {
      this.aiPartsGetAPI.getAllStorageLocations().subscribe(locs => {
        this.dataShare.setStorageLocations(locs);
        this.allStorageLocations = locs;
      })
      this.selectedPart = this.dataShare.getSelectedPart();
      this.allCategorys = this.dataShare.getPartCategorys();
    } else {
      this.category = true;
      this.categoryPath = this.findMatchingString(this.allCategoryStrings, this.headCategoryNode.name);
      this.dataShare.setHeadCategoryNode(defaultFlatNode());
    }
  }

  private openDialog(diagMsg: string): Observable<boolean> {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {message: diagMsg},
    });
    return dialogRef.afterClosed();
  }

  public updatePart(): void {
    this.snackbar.handleServerResonseCode(
        this.aiPartsPostAPI.updatePart(this.inputToPart(
            this.dataShare.getSelectedPart())));
    this._bottomSheetRef.dismiss();
  }

  public initPart(): void {
    this.snackbar.handleServerResonseCode(
        this.aiPartsPostAPI.initPart(this.inputToPart(this.dataShare.getSelectedPart())));
    this._bottomSheetRef.dismiss();
  }

  public initCategory(): void {
    if (this.headCategoryNode.name !== 'Root Category') {
      this.snackbar.handleServerResonseCode(
          this.aiPartsPostAPI.initPartCategory(this.inputToCategory())
      );
      this.dataShare.loadAllPartCategorys();
    } else {
      const category = this.inputToCategory();
      category.categoryString = 'Root Category' + ' ➤ ' + this.newCategory;
      this.snackbar.handleServerResonseCode(
          this.aiPartsPostAPI.initPartCategory(category)
      );
    }
    this.dataShare.loadAllPartCategorys();
    this._bottomSheetRef.dismiss();
  }

  private findMatchingString(array: string[], substring: string): string {
    let result = '';
    for (let i = 0; i < array.length; i++) {
      const currentString = array[i];
      const index = currentString.indexOf(substring);

      if (index !== -1) {
        result = currentString.substring(0, index + substring.length);
        break;
      }
    }
    return result;
  }

  private inputToPart(part: Part): Part {
    return {
      id: part.id,
      category_id: part.category_id,
      category: part.category,
      name: part.name,
      description: part.description,
      stockLevel: part.stockLevel,
      createDate: part.createDate,
      storageLocation_id: part.storageLocation_id,
      location: part.location
    };
  }

  private inputToCategory(): PartCategory {
    return {
      id: 0,
      name: this.newCategory,
      categoryPath: "",
      categoryString: this.categoryPath + ' ➤ ' + this.newCategory
    };
  }

  public deletePart(id: number): void {
    this.openDialog('Are you sure to delete this part?')
        .subscribe(_submit => {
          if (_submit) {
            this.aiPartsDeleteAPI.deletePart(id).subscribe(_resp => {
              this.snackbar.handleServerResonseCodeNoObs(_resp.status);
              this._bottomSheetRef.dismiss();
            });
          }
        });
  }
}
