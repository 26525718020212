import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Hardware} from "../server-detail/models/exporter-models";

@Injectable({
  providedIn: 'root'
})
export class ExporterApiService {

  constructor(private http: HttpClient) { }

  public getServerHardwareInfo(serverURL: string): Observable<Hardware> {
    return this.http.get<Hardware>(
        `${serverURL}/hardware/get`);
  }
}
