 <div class="status-bar">
    <div class="status-bar-container">
        <div class="status-bar-head">
            <div class="nf1-logo-container">
                <img class="nf1-logo-img" src="../../../../assets/images/nerd_force1_logo.png" alt="NF1">
            </div>
            <div class="server-upper-details">
                <div class="server-upper-details-status-bar">
                    <span class="server-hostname api-circle-fail">{{selectedServer.Hostname}}</span>
                </div>
                <div class="server-upper-details-footer">
                    <div class="ip-align">
                        <mat-icon class="ip-icon">fingerprint</mat-icon><span class="ip-span">{{selectedServer.IPv4}}</span>
                    </div>
                    <div class="ip-align">
                        <mat-icon class="ip-icon">fingerprint</mat-icon><span class="ip-span">{{selectedServer.IPv6}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="status-bar-nav">
            <ul class="nav-elements">
                <li (click)="setActiveClass('overview')" [routerLink]="'overview'" class="nav-element"><button mat-button>Overview</button><a [class.active]="navMap[0]"></a></li>
                <li (click)="setActiveClass('graphs')" [routerLink]="'graphs'" class="nav-element"><button mat-button>Graphs</button><a [class.active]="navMap[1]"></a></li>
                <li (click)="setActiveClass('backups')" [routerLink]="'backups'" class="nav-element"><button mat-button>Backups</button><a [class.active]="navMap[2]"></a></li>
                <li (click)="setActiveClass('logs')" [routerLink]="'logs'" class="nav-element"><button mat-button>Logs</button><a [class.active]="navMap[3]"></a></li>
            </ul>
        </div>
    </div>
</div>
<div class="inner-bound">
<!--<app-overview></app-overview>-->
    <router-outlet></router-outlet>
</div>

