<mat-list>
    <mat-list-item class="account-list-item" *ngFor="let accountHolder of accountHolders" [routerLink]="[accountHolder.Identifier]" >
            <span class="span-font top" matListItemTitle>{{accountHolder.Identifier}}</span>
            <span class="span-font bottom" matListItemLine>{{"   " + accountHolder.FirstName + "  " + accountHolder.FamilyName}}</span>
    </mat-list-item>
</mat-list>
<div class="add-account-holder-div">
    <button class="add-account-holder-button" mat-fab color="warn" (click)="toogle()">
        <mat-icon *ngIf="!expanded">add</mat-icon>
        <mat-icon *ngIf="expanded">expand_less</mat-icon>
    </button>
</div>
<div *ngIf="expanded">
    <mat-expansion-panel style="margin: 30px" expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                AccountHolder hinzufügen
            </mat-panel-title>
        </mat-expansion-panel-header>
    <div class="flex-container">
        <div class="flex-item">
            <form>
                <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                    <mat-label>Identifier</mat-label>
                    <input [(ngModel)]="newAccountHolder.Identifier" name="identifier" matInput #identifier maxlength="64">
                    <mat-hint align="end">{{identifier.value.length}}/64</mat-hint>
                </mat-form-field>
            </form>
        </div>
        <div class="flex-item">
            <form>
                <mat-form-field class="form-field-align" appearance="fill">
                    <mat-label>First Name</mat-label>
                    <input [(ngModel)]="newAccountHolder.FirstName" name="firstname" matInput #firstname maxlength="32">
                    <mat-hint align="end">{{firstname.value.length}}/32</mat-hint>
                </mat-form-field>
            </form>
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">
            <form>
                <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                    <mat-label>Familiy Name</mat-label>
                    <input [(ngModel)]="newAccountHolder.FamilyName" name="familiyname" matInput #familiyname maxlength="32">
                    <mat-hint align="end">{{familiyname.value.length}}/32</mat-hint>
                </mat-form-field>
            </form>
        </div>
        <div class="flex-item">
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">
            <div class="bottom-button-box full">
                <button [disabled]="checkAccountHolderInput(newAccountHolder) ? true: null"
                        (click)="initAccountholder()" class="update-button" mat-button color="primary">Add Accountholder</button>
            </div>
        </div>
    </div>
    </mat-expansion-panel>
</div>



