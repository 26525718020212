import {Component, OnInit} from '@angular/core';
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {AiPartsGetService} from "../services/api/ai-parts-get.service";
import {FlatNode, TreeNode} from "../models/ai-parts-models";
import {DataSharingService} from "../services/data-sharing.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {PartsBottomSheetComponent} from "../part/parts-bottom-sheet/parts-bottom-sheet.component";


@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss']
})
export class CategoryTreeComponent implements OnInit{
  public nodeInput = false;
  public groupedCategorys!: string[][];

  constructor(
      private aiPartsGetAPI: AiPartsGetService,
      private dataShare: DataSharingService,
      private _bottomSheet: MatBottomSheet
  ) {
  }

  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level
    };
  }

  public treeControl = new FlatTreeControl<FlatNode>(
      node => node.level,
      node => node.expandable,
  );

  private treeFlattener = new MatTreeFlattener(
      this._transformer,
      node => node.level,
      node => node.expandable,
      node => node.children,
  );

  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnInit(): void {
        this.buildTree();
    }

  public hasChild = (_: number, node: FlatNode) => node.expandable;
  public hasNoContent = (_: number, _nodeData: FlatNode) => _nodeData.name === '';

  private mergeNodes(root: TreeNode, nodes: TreeNode[]) {
    for (const node of nodes) {
      if (node.name === 'Root Category') {
        this.mergeNodes(root, node.children || []);
      } else {
        const existingNode = root.children?.find(child => child.name === node.name);
        if (existingNode) {
          this.mergeNodes(existingNode, node.children || []);
        } else {
          root.children = root.children || [];
          root.children.push(node);
        }
      }
    }
  }

  private buildTree() {
    const root: TreeNode = { name: 'Root Category' };
    this.dataShare.loadAllPartCategorys();
    this.dataShare.allPartCategorysObs.subscribe(cats => {
      if(cats[0].categoryPath !== '{}'){
        this.dataShare.setPartCategorys(cats)
        const nodes = cats.map(elem => JSON.parse(elem.categoryPath) as TreeNode);
        this.mergeNodes(root, nodes);
        try {
          this.dataSource.data = root.children || [];
          this.treeControl.expand(this.dataShare.getHeadCategoryNode());
        }
        catch  {
          this.dataSource.data = root.children || [];
        }
      }
    });
  }

  public changeCatergory(id: number): void {
    this.dataShare.setSelectedCategoryID(id);
  }

  public changeSelectedCategory(node: FlatNode) {
    this.dataShare.setSelectedTab(0);
    this.dataShare.setFullSearchBool(false);
    this.aiPartsGetAPI.getPartCategoryByName(node.name).subscribe(resp => {
      this.dataShare.setSelectedCategoryID(resp.id);
    });
  }

  public openDialogRoot(): void {
    this.dataShare.setHeadCategoryNode(
        {
          name: 'Root Category',
          expandable: false,
          level: 0
        });
    this._bottomSheet.open(
        PartsBottomSheetComponent,
        {
          panelClass: 'bottom-sheet-default'
        });
  }

  public openDialog(node: FlatNode): void {
    this.treeControl.expand(node);
    this.dataShare.setHeadCategoryNode(node);
    this._bottomSheet.open(
        PartsBottomSheetComponent,
        {
          panelClass: 'bottom-sheet-default'
        });
  }
}

