import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountTrackingRoutingModule } from './account-tracking-routing.module';
import {SelectorComponent} from "./selector/selector.component";
import {MatListModule} from "@angular/material/list";
import {UserInfoComponent} from "./user-info/user-info.component";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {UserEditComponent} from './user-edit/user-edit.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ReactiveFormsModule} from "@angular/forms";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSelectModule} from "@angular/material/select";
import { FormsModule } from '@angular/forms';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ProgressSpinnerComponent} from "../auxiliarys/progress-spinner/progress-spinner.component";
import {MatTreeModule} from '@angular/material/tree';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
  declarations: [
    SelectorComponent,
    UserInfoComponent,
    UserEditComponent,
      ProgressSpinnerComponent
  ],
  exports: [
    SelectorComponent,
    UserInfoComponent,
  ],
    imports: [
        CommonModule,
        AccountTrackingRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        MatListModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatInputModule,
        MatExpansionModule,
        MatSelectModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTreeModule,
        MatTooltipModule,
        MatChipsModule
    ]
})
export class AccountTrackingModule { }
