import { Injectable } from '@angular/core';
import {DataSharingService} from "./data-sharing.service";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
      private dataShare: DataSharingService
  ) {
  }

  public osidToName(osid: number): string  {
    let match = ""
    this.dataShare.getAllOperatingSystems().forEach(_os => {
      if(_os.OSID === osid){
        match = _os.Name
      }
    });
    return match
  }

  public standortIDToStandort(standortID: number): string  {
    let match = ""
    this.dataShare.getAllServerStandorte().forEach(_standort => {
      if(_standort.StandortID === standortID){
        match = _standort.Standort
      }
    });
    return match
  }

  public providerIDToName(providerID: number): string  {
    let match = ""
    this.dataShare.getAllProvider().forEach(_provider => {
      if(_provider.ProviderID=== providerID){
        match = _provider.Name
      }
    });
    return match
  }
}
