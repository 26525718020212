import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {KeycloakGuard} from "../keycloak.guard";
import {SplitScreenComponent} from "./split-screen/split-screen.component";
import {ServerComponent} from "./server/server.component";
import {WebsiteComponent} from "./website/website.component";
import {ServerDetailComponent} from "./server/server-detail/server-detail.component";
import {ServerOverviewComponent} from "./server/server-detail/overview/server-overview.component";
import {ServerGraphsComponent} from "./server/server-detail/graphs/server-graphs.component";
import {ServerBackupsComponent} from "./server/server-detail/backups/server-backups.component";
import {ServerLogsComponent} from "./server/server-detail/logs/server-logs.component";

const routes: Routes = [
  {
    path: 'ai-infra',
    component: SplitScreenComponent,
    canActivate: [KeycloakGuard],
    data: { roles: ['AI-Infra'] },
  },
  {
    path: 'ai-infra/server',
    component: ServerComponent,
    canActivate: [KeycloakGuard],
    data: { roles: ['AI-Infra'] },
  },
  {
    path: 'ai-infra/server/:hostname',
    component: ServerDetailComponent,
    canActivate: [KeycloakGuard],
    data: { roles: ['AI-Infra'] },
    children: [
      {
        path: 'overview',
        component: ServerOverviewComponent
      },
      {
        path: 'graphs',
        component: ServerGraphsComponent
      },
      {
        path: 'backups',
        component: ServerBackupsComponent
      },
      {
        path: 'logs',
        component: ServerLogsComponent
      },
    ]
  },
  {
    path: 'ai-infra/website',
    component: WebsiteComponent,
    canActivate: [KeycloakGuard],
    data: { roles: ['AI-Infra'] },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AiInfraRoutingModule { }
