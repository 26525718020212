<button mat-icon-button (click)="openDialogRoot()" ><mat-icon>add</mat-icon></button>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding="">
        <button mat-icon-button disabled></button>
        <span (click)="changeSelectedCategory(node)">{{node.name}}</span>
    </mat-tree-node>


    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding="">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name" >
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <span (click)="changeSelectedCategory(node)">{{node.name}}</span>
        <button mat-icon-button (click)="openDialog(node)"><mat-icon>add</mat-icon></button>
    </mat-tree-node>
</mat-tree>

