import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ServerComplete} from "../models/ai-infra-models";
import {MatTableDataSource} from "@angular/material/table";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {DataSharingService} from "../services/data-sharing.service";
import {AssembleService} from "../services/assemble.service";
import {concatMap, forkJoin, of} from "rxjs";
import {AiInfraGetService} from "../services/api/ai-infra-get.service";


@Component({
  selector: 'app-server',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.scss']
})
export class ServerComponent implements AfterViewInit {
  data!: ServerComplete[];
  displayedColumns: string[] = ['Hostname', 'IPv4', 'Location', 'options'];
  dataSource = new MatTableDataSource<ServerComplete>(this.data);
  allServersComplete: ServerComplete[] = [];

  constructor(
      private _liveAnnouncer: LiveAnnouncer,
      private dataShare: DataSharingService,
      private assemblerService: AssembleService,
      private aiInfraGetService: AiInfraGetService
  ) {}

  @ViewChild(MatSort) sort: any = MatSort;
  @ViewChild(MatPaginator) paginator: any = MatPaginator;

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.dataSource.paginator = this.paginator;
    setTimeout(() => {
      this.paginator.pageSize = 20;
    });
    this.dataShare.tableFilterValueObs.subscribe(_val => {
      this.dataSource.filter = _val;

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    });
    this.dataSource.sort = this.sort;
    forkJoin([
      this.aiInfraGetService.getAllProvider(),
      this.aiInfraGetService.getAllServers(),
      this.aiInfraGetService.getAllServerStandorte(),
      this.aiInfraGetService.getAllOperatingSystems()
    ]).pipe(
        concatMap(([provider, servers, standorte, os]) => {
          this.dataShare.setAllProvider(provider);
          this.dataShare.setAllServers(servers);
          this.dataShare.setAllServerStandorte(standorte);
          this.dataShare.setAllOperatingSystems(os);
          return of(null);
        })
    ).subscribe(()  => {
      this.dataSource.data = this.assemblerService.assembleServers();
      this.dataShare.allServersObs.subscribe(() => {
        this.dataSource.data = this.assemblerService.assembleServers();
      });
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
