import { Injectable } from '@angular/core';
import {
  OperatingSystem,
  Provider,
  Server,
  ServerComplete,
  ServerStandort
} from "../models/ai-infra-models";
import {defaultOS, defaultProvider, defaultServerStandort} from "../models/default-models";
import {DataSharingService} from "./data-sharing.service";

@Injectable({
  providedIn: 'root'
})
export class AssembleService {

  constructor(
      private dateShare: DataSharingService
  ) {}

  public assembleServers(): ServerComplete[] {
    const servers: Server[] = this.dateShare.getAllServers();
    const provider: Provider[] = this.dateShare.getAllProvider();
    const standorte: ServerStandort[] = this.dateShare.getAllServerStandorte();
    const os: OperatingSystem[] = this.dateShare.getAllOperatingSystems();
    const serverComplete: ServerComplete[] = [];
    servers.forEach(_server => {
          serverComplete.push({
            ServerID: _server.ServerID,
            Hostname: _server.Hostname,
            Alias: _server.Alias,
            IPv4: _server.IPv4,
            IPv6: _server.IPv6,
            OperatingSystem: this.matchOS(_server.FK_OSID, os),
            Provider: this.matchProvider(_server.FK_ProviderID, provider),
            Standort: this.matchStandort(_server.FK_StandortID, standorte),
            API_URL: _server.API_URL,
            Rsyslog: _server.Rsyslog
          });
    });
    return serverComplete;
  }

  private matchStandort(id: number, standort: ServerStandort[]): ServerStandort {
    let candidate: ServerStandort = defaultServerStandort();
    standort.forEach(_standort => {
      if(id === _standort.StandortID) {
        candidate = _standort;
      }
    });
    return candidate;
  }

  private matchProvider(id: number, provider: Provider[]): Provider {
    let candidate: Provider = defaultProvider();
    provider.forEach(_provider => {
      if(id === _provider.ProviderID) {
        candidate = _provider;
      }
    });
    return candidate;
  }

  private matchOS(id: number, os: OperatingSystem[]): OperatingSystem {
    let candidate: OperatingSystem = defaultOS();
    os.forEach(_os => {
      if(id === _os.OSID) {
        candidate = _os;
      }
    });
    return candidate;
  }

}
