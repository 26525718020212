import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {AccountComplete} from "../models/account-models";

@Injectable({
  providedIn: 'root'
})

export class DataSharingService {
  public wasSelectedSource = new BehaviorSubject<boolean>(false);
  private currentAccountIDSource = new BehaviorSubject<number>(0);
  private cachedAccountsSource = new BehaviorSubject<AccountComplete[]>([])
  private selectedAccountSource = new BehaviorSubject<AccountComplete>({
    AccountID: 0,
    AccountHolder: '',
    AccountType: {
      AccountTypeID: 0,
      AccountType: 'Unknown'
    },
    AccountStatus: {
      AccountStatusID: 0,
      AccountStatus: 'Unknown'
    },
    Username: '',
    Website: {
      WebsiteID: 0,
      WebsiteURL: '',
      WebsiteName: 'Unknown'
    },
    Server: {
      ServerID: 0,
      ServerName: 'Unknown',
      ServerIP: '',
      OS: {
        OSID: 0,
        Name: "Unknown"
      }},
    Application: {
      ApplicationID: 0,
      ApplicationName: 'Unknown',
      ApplicationType: {
        ApplicationTypeID: 0,
        ApplicationType: "Unknown"
      }
    }
  });
  wasSelected = this.wasSelectedSource.asObservable();
  currentAccountID = this.currentAccountIDSource.asObservable();
  cachedAccounts = this.cachedAccountsSource.asObservable();
  selectedAccount = this.selectedAccountSource.asObservable();
  accounts: AccountComplete[] = [];


  changeAccountID(id: number) {
    this.currentAccountIDSource.next(id);
  }

  shareAccounts(accounts: AccountComplete[], id: number) {
    this.cachedAccountsSource.next(accounts);
    this.cachedAccounts.subscribe(tmp => {
      this.accounts = tmp as AccountComplete[];
      accounts.forEach((acc) => {
        if(acc.AccountID == id) {
          this.selectedAccountSource.next(acc);
        }
      })
    });
  }
}
