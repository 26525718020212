<div class="outer-bound" *ngIf="selectedServer.ServerID === 0 && data === 'AddServer'">
    <div class="inner-container">
    <div class="flex-container">
        <div class="flex-item">
            <mat-card>
                <mat-card-content class="server-card">
                    <div class="card-div">
                        <span class="card-span">ServerID:</span>
                        <span *ngIf="selectedServer.ServerID !== 0" class="card-span">{{selectedServer.ServerID}}</span>
                        <span *ngIf="selectedServer.ServerID === 0" class="card-span">To be assigned</span>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item">
            <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                <mat-label>Hostname</mat-label>
                <input [(ngModel)]="selectedServer.Hostname" name="hostname" matInput [value]="selectedServer.Hostname" #hostname maxlength="64">
                <mat-hint align="end">{{hostname.value.length}}/64</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">

            <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                <mat-label>Alias</mat-label>
                <input [(ngModel)]="selectedServer.Alias" name="alias" matInput [value]="selectedServer.Alias" #alias maxlength="64">
                <mat-hint align="end">{{alias.value.length}}/64</mat-hint>
            </mat-form-field>

        </div>
        <div class="flex-item">

            <mat-form-field class="form-field-align" hintLabel="{{validationService.validateIPv4(ipv4.value)}}" appearance="fill">
                <mat-label>Server IPv4</mat-label>
                <input [(ngModel)]="selectedServer.IPv4" name="ipv4" matInput #ipv4 maxlength="32">
                <mat-hint align="end">{{ipv4.value.length}}/32</mat-hint>
            </mat-form-field>

        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">

            <mat-form-field class="form-field-align" hintLabel="{{validationService.validateIPv6(ipv6.value)}}" appearance="fill">
                <mat-label>Server IPv6</mat-label>
                <input [(ngModel)]="selectedServer.IPv6" name="ipv6" matInput #ipv6 maxlength="64">
                <mat-hint align="end">{{ipv6.value.length}}/64</mat-hint>
            </mat-form-field>

        </div>
        <div class="flex-item">
            <mat-form-field class="form-field-align" appearance="fill">
                <mat-label>OperatingSystem</mat-label>
                <mat-select [value]="translationService.osidToName(selectedServer.FK_OSID)">
                    <mat-option (click)="selectedServer.FK_OSID = os.OSID"
                                *ngFor="let os of allOperatingsystems" [value]="os.Name">{{os.Name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">

            <mat-form-field class="form-field-align" appearance="fill">
                <mat-label>Provider</mat-label>
                <mat-select [value]="translationService.providerIDToName(selectedServer.FK_ProviderID)">
                    <mat-option (click)="selectedServer.FK_ProviderID = provider.ProviderID"
                                *ngFor="let provider of allProvider" [value]="provider.Name">{{provider.Name}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="flex-item">
            <mat-form-field class="form-field-align" appearance="fill">
                <mat-label>Standort</mat-label>
                <mat-select [value]="translationService.standortIDToStandort(selectedServer.FK_StandortID)">
                    <mat-option (click)="selectedServer.FK_StandortID = standort.StandortID"
                                *ngFor="let standort of allServerStandorte" [value]="standort.Standort">{{standort.Standort}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">

            <mat-form-field class="form-field-align" hintLabel="{{validationService.validateURL(apiurl.value)}}" appearance="fill">
                <mat-label>API_URL</mat-label>
                <input [(ngModel)]="selectedServer.API_URL" name="apiurl" matInput [value]="selectedServer.API_URL" #apiurl maxlength="128">
                <mat-hint align="end">{{apiurl.value.length}}/128</mat-hint>
            </mat-form-field>

        </div>
        <div class="flex-item">
            <mat-card>
                <mat-card-content class="server-card">
                    <div class="card-div">
                        <span class="card-span">Connected to NF1SyncLogs:</span>
                        <mat-slide-toggle color="accent" [(ngModel)]="selectedServer.Rsyslog"></mat-slide-toggle>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">
            <button [disabled]="checkServerInput(selectedServer)"  (click)="initServer()" class="update-button" mat-button>Create Server</button>
        </div>
    </div>
    </div>
</div>

                <!-- ########## Operatingsystem ########## -->

<div class="outer-bound" *ngIf="data === 'AddOS' || data === 'EditOS'">
    <div class="inner-container">
        <div class="flex-container">
            <div class="flex-item">
                <mat-form-field *ngIf="data === 'EditOS'" class="form-field-align" appearance="fill">
                    <mat-label>OperatingSystem</mat-label>
                    <mat-select [value]="translationService.osidToName(selectedOS.OSID)">
                        <mat-option (click)="selectedOS = os"
                                    *ngFor="let os of allOperatingsystems" [value]="os.Name">{{os.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-card *ngIf="data === 'AddOS'">
                    <mat-card-content class="server-card">
                        <div class="card-div">
                            <span class="card-span">OSID:</span>
                            <span class="card-span">To be assigned</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="flex-item">
                <mat-form-field class="form-field-align" hintLabel="Max 32 characters" appearance="fill">
                    <mat-label>OperatingSystem</mat-label>
                    <input [(ngModel)]="selectedOS.Name" name="newosname" matInput [value]="selectedOS.Name" #newosname maxlength="32">
                    <mat-hint align="end">{{newosname.value.length}}/32</mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="flex-container">
            <div class="flex-item">
                <button *ngIf="data === 'AddOS'" [disabled]="selectedOS.Name === ''"  (click)="initOS()" class="update-button" mat-button>Create OperatingSystem</button>
                <button *ngIf="data === 'EditOS'" [disabled]="selectedOS.Name === ''"  (click)="updateOS()" class="update-button" mat-button>Update OperatingSystem</button>
            </div>
        </div>
    </div>
</div>
<div class="outer-bound" style="width: 50vw" *ngIf="data === 'DeleteOS'">
    <div class="inner-container scroll">
        <mat-list>
            <div class="list-container" *ngFor="let os of allOperatingsystems">
                <mat-list-item>
                    <div class="list-flex">
                        <div class="list-left">
                            <span class="span-line">{{os.Name}}</span>
                        </div>
                        <div class="list-right">
                            <button (click)="deleteOS(os.OSID)" class="delete-button" mat-mini-fab color="primary">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </div>

        </mat-list>

    </div>
</div>

<!-- ########## Provider ########## -->

<div class="outer-bound" *ngIf="data === 'AddProvider' || data === 'EditProvider'">
    <div class="inner-container">
        <div class="flex-container">
            <div class="flex-item">
                <mat-form-field *ngIf="data === 'EditProvider'" class="form-field-align" appearance="fill">
                    <mat-label>Provider</mat-label>
                    <mat-select [value]="translationService.providerIDToName(selectedProvider.ProviderID)">
                        <mat-option (click)="selectedProvider = provider"
                                    *ngFor="let provider of allProvider" [value]="provider.Name">{{provider.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-card *ngIf="data === 'AddProvider'">
                    <mat-card-content class="server-card">
                        <div class="card-div">
                            <span class="card-span">ProviderID:</span>
                            <span class="card-span">To be assigned</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="flex-item">
                <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                    <mat-label>Provider Name</mat-label>
                    <input [(ngModel)]="selectedProvider.Name" name="newosname" matInput [value]="selectedProvider.Name" #newprovider maxlength="64">
                    <mat-hint align="end">{{newprovider.value.length}}/64</mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="flex-container">
            <div class="flex-item">
                <button *ngIf="data === 'AddProvider'" [disabled]="selectedProvider.Name === ''"  (click)="initProvider()" class="update-button" mat-button>Create Provider</button>
                <button *ngIf="data === 'EditProvider'" [disabled]="selectedProvider.Name === ''"  (click)="updateProvider()" class="update-button" mat-button>Update Provider</button>
            </div>
        </div>
    </div>
</div>
<div class="outer-bound" style="width: 50vw" *ngIf="data === 'DeleteProvider'">
    <div class="inner-container scroll">
        <mat-list>
            <div class="list-container" *ngFor="let provider of allProvider">
                <mat-list-item>
                    <div class="list-flex">
                        <div class="list-left">
                            <span class="span-line">{{provider.Name}}</span>
                        </div>
                        <div class="list-right">
                            <button (click)="deleteProvider(provider.ProviderID)" class="delete-button" mat-mini-fab color="primary">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </div>

        </mat-list>

    </div>
</div>

<!-- ########## ServerStandort ########## -->

<div class="outer-bound" *ngIf="data === 'AddStandort' || data === 'EditStandort'">
    <div class="inner-container">
        <div class="flex-container">
            <div class="flex-item">
                <mat-form-field *ngIf="data === 'EditStandort'" class="form-field-align" appearance="fill">
                    <mat-label>Server Standort</mat-label>
                    <mat-select [value]="translationService.standortIDToStandort(selectedStandort.StandortID)">
                        <mat-option (click)="selectedStandort = standort"
                                    *ngFor="let standort of allServerStandorte" [value]="standort.Standort">{{standort.Standort}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-card *ngIf="data === 'AddStandort'">
                    <mat-card-content class="server-card">
                        <div class="card-div">
                            <span class="card-span">StandortID:</span>
                            <span class="card-span">To be assigned</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="flex-item">
                <mat-form-field class="form-field-align" hintLabel="Max 32 characters" appearance="fill">
                    <mat-label>Server Standort</mat-label>
                    <input [(ngModel)]="selectedStandort.Standort" name="newstandort" matInput [value]="selectedStandort.Standort" #newstandort maxlength="32">
                    <mat-hint align="end">{{newstandort.value.length}}/32</mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="flex-container">
            <div class="flex-item">
                <mat-form-field class="form-field-align" appearance="fill">
                    <mat-label>ISO3166</mat-label>
                    <mat-select [(value)]="selectedStandort.ISO3166">
                        <mat-option [value]="'DE'" (click)="selectedStandort.ISO3166 = 'DE'">DE</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
            <div class="flex-item">
                <button *ngIf="data === 'AddStandort'" [disabled]="selectedStandort.Standort === ''"
                        (click)="initStandort()" class="update-button" mat-button>Create Standort</button>
                <button *ngIf="data === 'EditStandort'" [disabled]="selectedStandort.Standort === ''"
                        (click)="updateStandort()" class="update-button" mat-button>Update Standort</button>
            </div>
        </div>
    </div>

<div class="outer-bound" style="width: 50vw" *ngIf="data === 'DeleteStandort'">
    <div class="inner-container scroll">
        <mat-list>
            <div class="list-container" *ngFor="let standort of allServerStandorte">
                <mat-list-item>
                    <div class="list-flex">
                        <div class="list-left">
                            <span class="span-line">{{standort.Standort}}</span>
                        </div>
                        <div class="list-right">
                            <button (click)="deleteStandort(standort.StandortID)" class="delete-button" mat-mini-fab color="primary">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </div>

        </mat-list>

    </div>
</div>