import {OperatingSystem, Provider, Server, ServerComplete, ServerStandort} from "./ai-infra-models";

export function defaultServers(): Server[] {
    return [{
        ServerID : 0,
        Hostname: "",
        Alias: "",
        IPv4: "",
        IPv6: "",
        FK_OSID: 0,
        FK_ProviderID: 0,
        FK_StandortID: 0,
        API_URL: "",
        Rsyslog: false
    }];
}

export function defaultServer(): Server {
    return {
        ServerID: 0,
        Hostname: "",
        Alias: "",
        IPv4: "",
        IPv6: "",
        FK_OSID: 0,
        FK_ProviderID: 0,
        FK_StandortID: 0,
        API_URL: "",
        Rsyslog: false
    };
}

export function defaultProviders(): Provider[] {
    return [{
        ProviderID: 0,
        Name: "",
    }];
}

export function defaultProvider(): Provider {
    return {
        ProviderID: 0,
        Name: ""
    };
}


export function defaultServersComplete(): ServerComplete[] {
    return [{
        ServerID : 0,
        Hostname: "",
        Alias: "",
        IPv4: "",
        IPv6: "",
        OperatingSystem: {
            OSID: 0,
            Name: ""
        },
        Provider: {
            ProviderID: 0,
            Name: ""
        },
        Standort: {
            StandortID: 0,
            Standort: "",
            ISO3166: ""
        },
        API_URL: "",
        Rsyslog: false
    }];
}

export function defaultServerStandorte(): ServerStandort[] {
    return [{
        StandortID: 0,
        Standort: "",
        ISO3166: ""
    }];
}

export function defaultServerStandort(): ServerStandort {
    return {
        StandortID: 0,
        Standort: "",
        ISO3166: ""
    };
}

export function defaultOS(): OperatingSystem {
    return {
        OSID: 0,
        Name: ""
    }
}

export function defaultOperatingSystems(): OperatingSystem[] {
    return [{
        OSID: 0,
        Name: ""
    }];
}