import { Component } from '@angular/core';

@Component({
  selector: 'app-account-tracking',
  templateUrl: './account-tracking.component.html',
  styleUrls: ['./account-tracking.component.scss']
})
export class AccountTrackingComponent {

}
