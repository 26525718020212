<div class="wrapper">
    <mat-accordion>
        <mat-expansion-panel expanded hideToggle (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Account bearbeiten  |  {{selectedAccount.AccountHolder}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                            <mat-label>Username</mat-label>
                            <input [(ngModel)]="selectedAccount.Username" name="username" matInput [value]="selectedAccount.Username" #username maxlength="64">
                            <mat-hint align="end">{{username.value.length}}/64</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>AccountHolder</mat-label>
                            <mat-select [(value)]="selectedAccount.AccountHolder" >
                                <mat-option *ngFor="let accountHolder of allAccountHolders" [value]="accountHolder.Identifier">{{accountHolder.Identifier}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Server</mat-label>
                            <mat-select [disabled]="editAccountBooleanMapper[config.accountEditMapper.server]"
                                        (selectionChange)="toogleBooleanMapper(config.accountEditMapper.server, editAccountBooleanMapper, selectedServerEDIT.value)"
                                        #selectedServerEDIT [(value)]="selectedAccount.Server.ServerName" >
                                <mat-option *ngFor="let server of allServers" [value]="server.ServerName">{{server.ServerName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Website</mat-label>
                            <mat-select [disabled]="editAccountBooleanMapper[config.accountEditMapper.website]"
                                        (selectionChange)="toogleBooleanMapper(config.accountEditMapper.website, editAccountBooleanMapper, selectedWebsiteEDIT.value)"
                                        #selectedWebsiteEDIT [(value)]="selectedAccount.Website.WebsiteName" >
                                <mat-option *ngFor="let website of allWebsites" [value]="website.WebsiteName">{{website.WebsiteName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Application</mat-label>
                            <mat-select [disabled]="editAccountBooleanMapper[config.accountEditMapper.application]"
                                        (selectionChange)="toogleBooleanMapper(config.accountEditMapper.application, editAccountBooleanMapper, selectedApplicationEDIT.value)"
                                        #selectedApplicationEDIT [(value)]="selectedAccount.Application.ApplicationName" >
                                <mat-option *ngFor="let application of allApplications" [value]="application.ApplicationName">{{application.ApplicationName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Account-Status</mat-label>
                            <mat-select [(value)]="selectedAccount.AccountStatus.AccountStatus" >
                                <mat-option *ngFor="let accountStatus of allAccountStatus" [value]="accountStatus.AccountStatus">{{accountStatus.AccountStatus}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Account-Type</mat-label>
                            <mat-select [(value)]="selectedAccount.AccountType.AccountType" >
                                <mat-option *ngFor="let accountType of allAccountTypes" [value]="accountType.AccountType">{{accountType.AccountType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>

                </div>
                <div class="flex-item">
                    <div class="bottom-button-box half">
                        <button [disabled]="selectedAccount.Username === '' || !wasSelected" (click)="updateAccount()" class="update-button" mat-button color="warn">Update Account</button>
                    </div>
            </div>
            </div>


        </mat-expansion-panel>

        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Account hinzufügen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                            <mat-label>Username</mat-label>
                            <input [(ngModel)]="accountComplete.Username" name="newusername" matInput [value]="accountComplete.Username" #newusername maxlength="64">
                            <mat-hint align="end">{{newusername.value.length}}/64</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>AccountHolder</mat-label>
                            <mat-select [disabled]="selectedAccountTypeADD.value === config.sharedAccount" [(value)]="accountComplete.AccountHolder" >
                                <mat-option *ngFor="let accountHolder of allAccountHolders" [value]="accountHolder.Identifier">{{accountHolder.Identifier}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Server</mat-label>
                            <mat-select [disabled]="addAccountBooleanMapper[config.accountAddMapper.server]"
                                        (selectionChange)="toogleBooleanMapper(config.accountAddMapper.server, addAccountBooleanMapper, selectedServerADD.value)"
                                        #selectedServerADD [(value)]="accountComplete.Server.ServerName" >
                                <mat-option *ngFor="let server of allServers" [value]="server.ServerName">{{server.ServerName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Website</mat-label>
                            <mat-select [disabled]="addAccountBooleanMapper[config.accountAddMapper.website]"
                                        (selectionChange)="toogleBooleanMapper(config.accountAddMapper.website, addAccountBooleanMapper, selectedWebsiteADD.value)"
                                        #selectedWebsiteADD [(value)]="accountComplete.Website.WebsiteName" >
                                <mat-option *ngFor="let website of allWebsites" [value]="website.WebsiteName">{{website.WebsiteName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Application</mat-label>
                            <mat-select [disabled]="addAccountBooleanMapper[config.accountAddMapper.application]"
                                        (selectionChange)="toogleBooleanMapper(config.accountAddMapper.application, addAccountBooleanMapper, selectedApplicationADD.value)"
                                        #selectedApplicationADD [(value)]="accountComplete.Application.ApplicationName" >
                                <mat-option *ngFor="let application of allApplications" [value]="application.ApplicationName">{{application.ApplicationName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Account-Status</mat-label>
                            <mat-select [(value)]="accountComplete.AccountStatus.AccountStatus" >
                                <mat-option *ngFor="let accountStatus of allAccountStatus" [value]="accountStatus.AccountStatus">{{accountStatus.AccountStatus}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Account-Type</mat-label>
                            <mat-select #selectedAccountTypeADD [(value)]="accountComplete.AccountType.AccountType" >
                                <mat-option *ngFor="let accountType of allAccountTypes" [value]="accountType.AccountType">{{accountType.AccountType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>

                </div>
                <div class="flex-item">
                    <div class="bottom-button-box half">
                        <button [disabled]="checkAccountInput(accountComplete, selectedAccountTypeADD.value, sharedAccountHolders)" (click)="initAccount(selectedAccountTypeADD.value)" class="update-button" mat-button color="warn">Create Account</button>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedAccountTypeADD.value === config.sharedAccount" class="flex-container">
                <div class="flex-item">
            <form>
                <mat-form-field style="width: 80%" class="form-field-align" appearance="fill">
                    <mat-label>Link AccountHolder</mat-label>
                    <mat-chip-grid #chipGrid aria-label="Fruit selection">
                        <mat-chip-row *ngFor="let accountholder of sharedAccountHolders" (removed)="sharedAccountRemove(accountholder)">
                            {{accountholder}}
                            <button matChipRemove [attr.aria-label]="'remove ' + accountholder">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="Link to AccountHolders" #sharedAccountInput [formControl]="formControl"
                           [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           (matChipInputTokenEnd)="sharedAccountAdd($event)"/>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="sharedAccountSelected($event)">
                        <mat-option *ngFor="let accountHolder of filteredSharedAccountHolder | async" [value]="accountHolder">
                            {{accountHolder}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </form>
        </div>
                <div class="flex-item">
                </div>
    </div>
        </mat-expansion-panel>


        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Server hinzufügen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                            <mat-label>Server Name</mat-label>
                            <input [(ngModel)]="newServer.ServerName" name="servername" matInput #serverName maxlength="64">
                            <mat-hint align="end">{{serverName.value.length}}/64</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" hintLabel="{{validationService.validateIPv4(ipv4.value)}}" appearance="fill">
                            <mat-label>Server IPv4</mat-label>
                            <input [(ngModel)]="newServer.ServerIP" name="ipv4" matInput #ipv4 maxlength="32">
                            <mat-hint align="end">{{ipv4.value.length}}/32</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Operating-System</mat-label>
                            <mat-select [(value)]="newServer.OS.Name">
                                <mat-option *ngFor="let os of allOperatingSystems" [value]="os.Name">{{os.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <div class="bottom-button-box half">
                        <button [disabled]="checkServerInput(newServer) ? true: null" (click)="initServer()" class="update-button" mat-button color="warn">Create Server</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>




        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Website hinzufügen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                            <mat-label>Website Name</mat-label>
                            <input [(ngModel)]="newWebsite.WebsiteName" name="websitename" matInput #websiteName maxlength="64">
                            <mat-hint align="end">{{websiteName.value.length}}/64</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align"
                                        hintLabel="{{validationService.validateURL(url.value)}}" appearance="fill">
                            <mat-label>Website full URL</mat-label>
                            <input [(ngModel)]="newWebsite.WebsiteURL" name="url" matInput #url maxlength="128">
                            <mat-hint align="end">{{url.value.length}}/128</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <div class="bottom-button-box full">
                        <button [disabled]="checkWebsiteInput(newWebsite) ? true: null" (click)="initWebsite()" class="update-button" mat-button color="warn">Create Website</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>


        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Application hinzufügen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-container">
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                            <mat-label>Application Name</mat-label>
                            <input [(ngModel)]="newApplication.ApplicationName" name="applicationname" matInput #applicationName maxlength="64">
                            <mat-hint align="end">{{applicationName.value.length}}/64</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
                <div class="flex-item">
                    <form>
                        <mat-form-field class="form-field-align" appearance="fill">
                            <mat-label>Application-Type</mat-label>
                            <mat-select [(value)]="newApplication.ApplicationType.ApplicationType">
                                <mat-option *ngFor="let appt of allApplicationTypes" [value]="appt.ApplicationType">{{appt.ApplicationType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <div class="bottom-button-box full">
                        <button [disabled]="checkApplicationInput(newApplication) ? true: null" (click)="initApplication()" class="update-button" mat-button color="warn">Create Application</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Delete Entitys
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

                    <mat-accordion>
                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Delete Server
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="flex-container">
                                <div class="flex-item">
                                    <form>
                                        <mat-form-field class="form-field-align" appearance="fill">
                                            <mat-label>Server</mat-label>
                                            <mat-select [(value)]="selectedServer.ServerName" >
                                                <mat-option *ngFor="let server of allServers" [value]="server.ServerName">{{server.ServerName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div class="flex-item">
                                    <div class="bottom-button-box full">
                                        <button [disabled]="selectedServer.ServerName === ''" (click)="deleteServer()"
                                                class="update-button" mat-button color="warn"
                                                matTooltip="Deletion of server, also results in deletion of all linked accounts">
                                        Delete Server</button>
                                    </div>
                                </div>
                            </div>

                        </mat-expansion-panel>

                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Delete Website
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="flex-container">
                                <div class="flex-item">
                                    <form>
                                        <mat-form-field class="form-field-align" appearance="fill">
                                            <mat-label>Website</mat-label>
                                            <mat-select [(value)]="selectedWebsite.WebsiteName" >
                                                <mat-option *ngFor="let website of allWebsites" [value]="website.WebsiteName">{{website.WebsiteName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div class="flex-item">
                                    <div class="bottom-button-box full">
                                        <button [disabled]="selectedWebsite.WebsiteName === ''" (click)="deleteWebsite()"
                                                class="update-button" mat-button color="warn"
                                                matTooltip="Deletion of website, also results in deletion of all linked accounts">
                                            Delete Website</button>
                                    </div>
                                </div>
                            </div>

                        </mat-expansion-panel>


                    <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Delete Application
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="flex-container">
                            <div class="flex-item">
                                <form>
                                    <mat-form-field class="form-field-align" appearance="fill">
                                        <mat-label>Application</mat-label>
                                        <mat-select [(value)]="selectedApplication.ApplicationName" >
                                            <mat-option *ngFor="let application of allApplications" [value]="application.ApplicationName">{{application.ApplicationName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </form>
                            </div>
                            <div class="flex-item">
                                <div class="bottom-button-box full">
                                    <button [disabled]="selectedApplication.ApplicationName === ''" (click)="deleteApplication()"
                                            class="update-button" mat-button color="warn"
                                            matTooltip="Deletion of application, also results in deletion of all linked accounts">
                                        Delete Application</button>
                                </div>
                            </div>
                        </div>

                    </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>

        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Edit Entitys
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Edit Server
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="flex-container-single">
                        <div class="center-load">
                            <mat-form-field style="width: 90%;" class="form-field-align" appearance="fill">
                                <mat-label>Server</mat-label>
                                <mat-select [value]="updateServerEntity.ServerName" #updateServerSelect
                                            (selectionChange)=" loadUpdateServer(updateServerSelect.value)">
                                    <mat-option *ngFor="let server of allServers"
                                                [value]="server.ServerName">{{server.ServerName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex-container">
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                                    <mat-label>Server Name</mat-label>
                                    <input [(ngModel)]="selectedUpdateServerName" name="servername" matInput #serverUpdateName maxlength="64">
                                    <mat-hint align="end">{{serverUpdateName.value.length}}/64</mat-hint>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align" hintLabel="{{validationService.validateIPv4(ipv4Update.value)}}" appearance="fill">
                                    <mat-label>Server IPv4</mat-label>
                                    <input [(ngModel)]="updateServerEntity.ServerIP" name="ipv4" matInput #ipv4Update maxlength="32">
                                    <mat-hint align="end">{{ipv4Update.value.length}}/32</mat-hint>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                    <div class="flex-container">
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align" appearance="fill">
                                    <mat-label>Operating-System</mat-label>
                                    <mat-select [(value)]="updateServerEntity.OS.Name">
                                        <mat-option *ngFor="let os of allOperatingSystems" [value]="os.Name">{{os.Name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                    <div class="flex-item">

                    </div>
                    </div>
                        <div class="flex-container">
                            <div class="flex-item">
                                <div class="bottom-button-box center">
                                    <button [disabled]="checkServerInput(updateServerEntity) || selectedUpdateServerName === ''" (click)="updateUpdateServer()"
                                            class="update-button" mat-button color="warn">Update Server</button>
                                </div>
                            </div>
                        </div>


                </mat-expansion-panel>

                <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Edit Website
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="flex-container-single">
                        <div class="center-load">
                            <mat-form-field style="width: 90%;" class="form-field-align" appearance="fill">
                                <mat-label>Website</mat-label>
                                <mat-select [value]="updateWebsiteEntity.WebsiteName" #updateWebsiteSelect
                                            (selectionChange)=" loadUpdateWebsite(updateWebsiteSelect.value)">
                                    <mat-option *ngFor="let website of allWebsites" (click)="loadUpdateWebsite(website.WebsiteName)"
                                                [value]="website.WebsiteName">{{website.WebsiteName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex-container">
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                                    <mat-label>Website Name</mat-label>
                                    <input [(ngModel)]="selectedUpdateWebsiteName" name="websitename" matInput #websiteUpdateName maxlength="64">
                                    <mat-hint align="end">{{websiteUpdateName.value.length}}/64</mat-hint>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align"
                                                hintLabel="{{validationService.validateURL(updateurl.value)}}" appearance="fill">
                                    <mat-label>Website URL</mat-label>
                                    <input [(ngModel)]="updateWebsiteEntity.WebsiteURL" name="updateurl" matInput #updateurl maxlength="128">
                                    <mat-hint align="end">{{updateurl.value.length}}/128</mat-hint>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                    <div class="flex-container">
                        <div class="flex-item">
                            <div class="bottom-button-box center">
                                <button [disabled]="checkWebsiteInput(updateWebsiteEntity) || selectedUpdateWebsiteName === ''" (click)="updateUpdateWebsite()"
                                        class="update-button" mat-button color="warn">Update Website</button>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Edit Application
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="flex-container-single">
                        <div class="center-load">
                            <mat-form-field style="width: 90%;" class="form-field-align" appearance="fill">
                                <mat-label>Application</mat-label>
                                <mat-select [value]="updateApplicationEntity.ApplicationName" #updateApplicationSelect
                                            (selectionChange)=" loadUpdateApplication(updateApplicationSelect.value)">
                                    <mat-option *ngFor="let application of allApplications" (click)="loadUpdateApplication(application.ApplicationName)"
                                                [value]="application.ApplicationName">{{application.ApplicationName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex-container">
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                                    <mat-label>Application Name</mat-label>
                                    <input [(ngModel)]="selectedUpdateApplicationName" name="applicationname" matInput #applicationUpdateName maxlength="64">
                                    <mat-hint align="end">{{applicationUpdateName.value.length}}/64</mat-hint>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align" appearance="fill">
                                    <mat-label>Application Type</mat-label>
                                    <mat-select [(value)]="updateApplicationEntity.ApplicationType.ApplicationType">
                                        <mat-option *ngFor="let applicationType of allApplicationTypes"
                                                    [value]="applicationType.ApplicationType">{{applicationType.ApplicationType}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                    <div class="flex-container">
                        <div class="flex-item">
                            <div class="bottom-button-box center">
                                <button [disabled]="checkApplicationInput(updateApplicationEntity) || selectedUpdateApplicationName === ''" (click)="updateUpdateApplication()"
                                        class="update-button" mat-button color="warn">Update Application</button>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Edit Sub-EntityClasses
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>AccountStatus</mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>


                    <mat-accordion>
                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Add AccountStatus</mat-panel-title>
                                <mat-panel-description></mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="flex-container">
                                <div class="flex-item">
                                    <form>
                                        <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                                            <mat-label>AccountStatus</mat-label>
                                            <input [(ngModel)]="selectedAccountStatusAdd.AccountStatus" name="selectedAccountStatusAdd"
                                                    matInput #init_AccountStatus maxlength="64">
                                            <mat-hint align="end">{{init_AccountStatus.value.length}}/64</mat-hint>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div class="flex-item">
                                    <div class="bottom-button-box full">
                                        <button [disabled]="selectedAccountStatusAdd.AccountStatus === ''" (click)="initAccountStatus()"
                                                class="update-button" mat-button color="warn"
                                                matTooltip="Deletion of account-status, also results in deletion of all linked accounts">
                                            Add AccountStatus</button>
                                    </div>
                                </div>
                            </div>

                        </mat-expansion-panel>

                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Delete AccountStatus</mat-panel-title>
                                <mat-panel-description></mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="flex-container">
                                <div class="flex-item">
                                    <form>
                                        <mat-form-field class="form-field-align" appearance="fill">
                                            <mat-label>AccountStatus</mat-label>
                                            <mat-select [(value)]="selectedAccountStatusDelete.AccountStatus" #delete_AccountStatus>
                                            <mat-option *ngFor="let accountStatus of allAccountStatus"
                                                        [value]="accountStatus.AccountStatus">{{accountStatus.AccountStatus}}</mat-option>
                                        </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div class="flex-item">
                                    <div class="bottom-button-box full">
                                        <button [disabled]="selectedAccountStatusDelete.AccountStatus === ''" (click)="deleteAccountStatus()"
                                                class="update-button" mat-button color="warn"
                                                matTooltip="Deletion of account-status, also results in deletion of all linked accounts">
                                            Delete AccountStatus</button>
                                    </div>
                                </div>
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-expansion-panel>

                <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>AccountType</mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-accordion>
                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Add AccountType</mat-panel-title>
                                <mat-panel-description></mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="flex-container">
                                <div class="flex-item">
                                    <form>
                                        <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                                            <mat-label>AccountType</mat-label>
                                            <input [(ngModel)]="selectedAccountTypeAdd.AccountType" name="selectedAccountTypeAdd"
                                                   matInput #init_AccountType maxlength="64">
                                            <mat-hint align="end">{{init_AccountType.value.length}}/64</mat-hint>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div class="flex-item">
                                    <div class="bottom-button-box full">
                                        <button [disabled]="selectedAccountTypeAdd.AccountType === ''" (click)="initAccountType()"
                                                class="update-button" mat-button color="warn"
                                                matTooltip="Deletion of account-type, also results in deletion of all linked accounts">
                                            Add AccountType</button>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Delete AccountType</mat-panel-title>
                                <mat-panel-description></mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="flex-container">
                                <div class="flex-item">
                                    <form>
                                        <mat-form-field class="form-field-align" appearance="fill">
                                            <mat-label>AccountType</mat-label>
                                            <mat-select [(value)]="selectedAccountTypeDelete.AccountType" #delete_AccountType>
                                                <mat-option *ngFor="let accountType of allAccountTypes"
                                                            [value]="accountType.AccountType">{{accountType.AccountType}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div class="flex-item">
                                    <div class="bottom-button-box full">
                                        <button [disabled]="selectedAccountTypeDelete.AccountType === ''" (click)="deleteAccountType()"
                                                class="update-button" mat-button color="warn"
                                                matTooltip="Deletion of account-type, also results in deletion of all linked accounts">
                                            Delete AccountType</button>
                                    </div>
                                </div>
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>

                </mat-expansion-panel>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>OperatingSystem</mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-accordion>
                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Add OperatingSystem</mat-panel-title>
                                <mat-panel-description></mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="flex-container">
                                <div class="flex-item">
                                    <form>
                                        <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                                            <mat-label>OperatingSystem</mat-label>
                                            <input [(ngModel)]="selectedOperatingSystemAdd.Name" name="selectedOperatingSystemAdd"
                                                   matInput #init_OperatingSystem maxlength="64">
                                            <mat-hint align="end">{{init_OperatingSystem.value.length}}/64</mat-hint>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div class="flex-item">
                                    <div class="bottom-button-box full">
                                        <button [disabled]="selectedOperatingSystemAdd.Name === ''" (click)="initOperatingsystem()"
                                                class="update-button" mat-button color="warn"
                                                matTooltip="Deletion of operating-system, also results in deletion of all linked servers">
                                            Add OperatingSystem</button>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Delete OperatingSystem</mat-panel-title>
                                <mat-panel-description></mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="flex-container">
                                <div class="flex-item">
                                    <form>
                                        <mat-form-field class="form-field-align" appearance="fill">
                                            <mat-label>OperatingSystem</mat-label>
                                            <mat-select [(value)]="selectedOperatingSystemDelete.Name" #delete_OperatingSystem>
                                                <mat-option *ngFor="let os of allOperatingSystems"
                                                            [value]="os.Name">{{os.Name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div class="flex-item">
                                    <div class="bottom-button-box full">
                                        <button [disabled]="selectedOperatingSystemDelete.Name === ''" (click)="deleteOperatingSystem()"
                                                class="update-button" mat-button color="warn"
                                                matTooltip="Deletion of operating-system, also results in deletion of all linked servers">
                                            Delete OperatingSystem</button>
                                    </div>
                                </div>
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-expansion-panel>
            </mat-accordion>

        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>ApplicationType</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Add ApplicationType</mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="flex-container">
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align" hintLabel="Max 64 characters" appearance="fill">
                                    <mat-label>ApplicationType</mat-label>
                                    <input [(ngModel)]="selectedApplicationTypeAdd.ApplicationType" name="selectedApplicationTypeAdd"
                                           matInput #init_ApplicationType maxlength="64">
                                    <mat-hint align="end">{{init_ApplicationType.value.length}}/64</mat-hint>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="flex-item">
                            <div class="bottom-button-box full">
                                <button [disabled]="selectedApplicationTypeAdd.ApplicationType === ''" (click)="initApplicationType()"
                                        class="update-button" mat-button color="warn"
                                        matTooltip="Deletion of application-type, also results in deletion of all linked applications">
                                    Add ApplicationType</button>
                            </div>
                        </div>
                    </div>

                </mat-expansion-panel>

                <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Delete ApplicationType</mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="flex-container">
                        <div class="flex-item">
                            <form>
                                <mat-form-field class="form-field-align" appearance="fill">
                                    <mat-label>ApplicationType</mat-label>
                                    <mat-select [(value)]="selectedApplicationTypeDelete.ApplicationType" #delete_ApplicationType>
                                        <mat-option *ngFor="let at of allApplicationTypes"
                                                    [value]="at.ApplicationType">{{at.ApplicationType}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="flex-item">
                            <div class="bottom-button-box full">
                                <button [disabled]="selectedApplicationTypeDelete.ApplicationType === ''" (click)="deleteApplicationType()"
                                        class="update-button" mat-button color="warn"
                                        matTooltip="Deletion of application-type, also results in deletion of all linked applications">
                                    Delete ApplicationType</button>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>
        </mat-expansion-panel>
    </mat-accordion>
</div>

