import {Component, ViewEncapsulation} from '@angular/core';
import {DataSharingService} from "../../services/data-sharing.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {Server} from "../../models/ai-infra-models";
import {defaultServer} from "../../models/default-models";
import {ServerBottomSheetComponent} from "../server-bottom-sheet/server-bottom-sheet.component";

@Component({
  selector: 'app-top-down-menu',
  templateUrl: './top-down-menu.component.html',
  styleUrls: ['./top-down-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopDownMenuComponent {
  divLoaded = false;
  panelOpenState = false;

  constructor(
      private dataShare: DataSharingService,
      private _bottomSheet: MatBottomSheet,
  ) {}

  toggleDropDown() {
    this.divLoaded = !this.divLoaded;
  }

  forwardFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataShare.setTableFilterValue(filterValue.trim().toLowerCase());
  }

  public openServerForm(id: number): void {
    if(id !== 0) {
      this.dataShare.setSelectedServer(this.getSeletedServer(id));
    }
    else {
      this.dataShare.setSelectedServer(defaultServer());
    }
    this._bottomSheet.open(
        ServerBottomSheetComponent,
        {
          panelClass: 'bottom-sheet-default',
          data: "AddServer"
        });
  }

  public openOSForm(mode: string): void {
    if(mode === 'add') {
      this._bottomSheet.open(
          ServerBottomSheetComponent,
          {
            panelClass: 'bottom-sheet-default',
            data: "AddOS"
          });
    }
    if(mode === 'edit') {
      this._bottomSheet.open(
          ServerBottomSheetComponent,
          {
            panelClass: 'bottom-sheet-default',
            data: "EditOS"
          });
    }
      if(mode === 'delete') {
          this._bottomSheet.open(
              ServerBottomSheetComponent,
              {
                  panelClass: 'bottom-sheet-default',
                  data: "DeleteOS"
              });
      }
  }

    public openProviderForm(mode: string): void {
        if(mode === 'add') {
            this._bottomSheet.open(
                ServerBottomSheetComponent,
                {
                    panelClass: 'bottom-sheet-default',
                    data: "AddProvider"
                });
        }
        if(mode === 'edit') {
            this._bottomSheet.open(
                ServerBottomSheetComponent,
                {
                    panelClass: 'bottom-sheet-default',
                    data: "EditProvider"
                });
        }
        if(mode === 'delete') {
            this._bottomSheet.open(
                ServerBottomSheetComponent,
                {
                    panelClass: 'bottom-sheet-default',
                    data: "DeleteProvider"
                });
        }
    }

    public openStandortForm(mode: string): void {
        if(mode === 'add') {
            this._bottomSheet.open(
                ServerBottomSheetComponent,
                {
                    panelClass: 'bottom-sheet-default',
                    data: "AddStandort"
                });
        }
        if(mode === 'edit') {
            this._bottomSheet.open(
                ServerBottomSheetComponent,
                {
                    panelClass: 'bottom-sheet-default',
                    data: "EditStandort"
                });
        }
        if(mode === 'delete') {
            this._bottomSheet.open(
                ServerBottomSheetComponent,
                {
                    panelClass: 'bottom-sheet-default',
                    data: "DeleteStandort"
                });
        }
    }

  private getSeletedServer(id: number): Server {
    let matchedServer: Server = defaultServer();
    this.dataShare.getAllServers().forEach(_server => {
      if (_server.ServerID === id) {
        matchedServer = _server
      }
    });
    return matchedServer;
  }
}

