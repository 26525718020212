import {Disk, Hardware} from "./exporter-models";

export function defaultDisk(): Disk {
    return {
        disk_size_gb: 0,
        disk_used_gb: 0,
        disk_free_gb: 0,
        disk_percent: 0,
        fs_type: "",
        device: ""
    }
}

export function defaultDisks(): Disk[] {
    return [{
        disk_size_gb: 0,
        disk_used_gb: 0,
        disk_free_gb: 0,
        disk_percent: 0,
        fs_type: "",
        device: ""
    }];
}

export function defaultHardware(): Hardware {
    return {
        cpu_count: 0,
        ram_mb: 0,
        disks: defaultDisks()
    }

}