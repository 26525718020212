<div class="ai-parts-header">
    <mat-form-field style="width: 100%" class="filter">
        <mat-label>StorageLocation</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter Value" #input>
    </mat-form-field>
    <button (click)="openLocationsForm(0)"  mat-flat-button color="primary" class="add-part">
        <mat-icon style="width: 25px">library_add</mat-icon><span style="white-space: nowrap">Add Location</span>
    </button>
</div>

<div class="table-back">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name" sortActionDescription="Sort by Name">Name</th>
            <td mat-cell *matCellDef="let element">
                <button mat-button [matMenuTriggerFor]="menu">{{element.name}}</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openLocationsForm(element.id)"><mat-icon>edit</mat-icon>Edit</button>
                    <button mat-menu-item [cdkCopyToClipboard]="element.name"><mat-icon>file_copy</mat-icon>Copy</button>
                </mat-menu>
            </td>
        </ng-container>


        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="category_id" sortActionDescription="Sort by Description">Standort</th>
            <td mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>


        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="id" sortActionDescription="Sort by Stock">id</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
</div>
        <mat-paginator aria-label="Select page of users"></mat-paginator>


