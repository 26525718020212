import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AccountComplete,
  AccountHolder,
  AccountStatus, AccountType,
  Application, ApplicationType, OperatingSystem,
  Server,
  Website
} from "../../models/account-models";
import {urlBase} from "./config";

@Injectable({
  providedIn: 'root'
})
export class AccountApiGetService {

  constructor(private http: HttpClient) {}

  public getAllAccountHolderNotZero(): Observable<AccountHolder[]> {
    return this.http.get<AccountHolder[]>(
        `${urlBase}/accountholder/all/not-zero`);
  }

  public getAllAccountHolder(): Observable<AccountHolder[]> {
    return this.http.get<AccountHolder[]>(
        `${urlBase}/accountholder/all`);
  }

  public getAllAccountHolderAccounts(aid: number): Observable<AccountComplete[]> {
    return this.http.get<AccountComplete[]>(
        `${urlBase}/accountholder/get-all/${aid}`);
  }

  public getAllServers(): Observable<Server[]> {
    return this.http.get<Server[]>(
        `${urlBase}/server/all`);
  }

  public getAllOperatingSystems(): Observable<OperatingSystem[]> {
    return this.http.get<OperatingSystem[]>(
        `${urlBase}/os/all`);
  }

  public getAllWebsites(): Observable<Website[]> {
    return this.http.get<Website[]>(
        `${urlBase}/website/all`);
  }

  public getAllApplications(): Observable<Application[]> {
    return this.http.get<Application[]>(
        `${urlBase}/application/all`);
  }

  public getAllApplicationsTypes(): Observable<ApplicationType[]> {
    return this.http.get<ApplicationType[]>(
        `${urlBase}/applicationtype/all`);
  }

  public getAllAccountStatus(): Observable<AccountStatus[]> {
    return this.http.get<AccountStatus[]>(
        `${urlBase}/accountstatus/all`);
  }

  public getAllAccountTypes(): Observable<AccountType[]> {
    return this.http.get<AccountType[]>(
        `${urlBase}/accounttype/all`);
  }

}
