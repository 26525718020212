import { Injectable } from '@angular/core';
import {KeycloakService} from "keycloak-angular";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(
      private keycloakService: KeycloakService
  ) { }

  public validateIPv4(pattern: string): string {
    const regex = new RegExp(
        "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$");
    if(regex.test(pattern)){
      return "Valid IPv4 Address";
    }
    return "Unvalid IPv4 Address"
  }

  public validateIPv6(pattern: string): string {
    const regex = new RegExp(
        "^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:" +
        "|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}" +
        "|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}" +
        "|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})" +
        "|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}" +
        "|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]" +
        "|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]" +
        "|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))");
    if(regex.test(pattern)){
      return "Valid IPv6 Address";
    }
    return "Unvalid IPv6 Address"
  }

  public validateURL(pattern: string): string {
    const regex = new RegExp(
        '^((http|https):\\/\\/)[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9-]+)+((\\/)[\\w#]+)*(\\/\\w+\\?[a-zA-Z0-9_]+=\\w+(&[a-zA-Z0-9_]+=\\w+)*)?\\/?$');
    if(regex.test(pattern)){
      return "Valid URL";
    }
    return "Unvalid URL"
  }

  public checkUserRole(moduleRole: string[]) : boolean{
    let hits = 0;
    moduleRole.forEach(role => {
      if (this.keycloakService.isUserInRole(role)) {
        hits++;
      }
    });
    return hits !== 0;
  }

}
