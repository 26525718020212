<div class="top-container">
    <div class="loaded-div ease-out" *ngIf="divLoaded">
        <a (click)="openServerForm(0)" class="button parallelogram">
            <span class="skew-fix">Add Server</span>
        </a>
        <a [matMenuTriggerFor]="entitys" class="button parallelogram">
            <span class="skew-fix">Entitys</span>
        </a>
        <mat-menu #entitys="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="operatingsystem">OperatingSystem</button>
            <button mat-menu-item [matMenuTriggerFor]="provider">Provider</button>
            <button mat-menu-item [matMenuTriggerFor]="standort">Standort</button>
        </mat-menu>

        <mat-menu #operatingsystem="matMenu">
            <button (click)="openOSForm('add')" mat-menu-item>Add OS</button>
            <button (click)="openOSForm('edit')" mat-menu-item>Edit OS</button>
            <button (click)="openOSForm('delete')" mat-menu-item>Delete OS</button>
        </mat-menu>

        <mat-menu #provider="matMenu">
            <button (click)="openProviderForm('add')" mat-menu-item>Add Provider</button>
            <button (click)="openProviderForm('edit')" mat-menu-item>Edit Provider</button>
            <button (click)="openProviderForm('delete')" mat-menu-item>Delete Provider</button>
        </mat-menu>

        <mat-menu #standort="matMenu">
            <button (click)="openStandortForm('add')" mat-menu-item>Add Standort</button>
            <button (click)="openStandortForm('edit')" mat-menu-item>Edit Standort</button>
            <button (click)="openStandortForm('delete')" mat-menu-item>Delete Standort</button>
        </mat-menu>

        <a class="input-field parallelogram">
            <mat-icon class="skew-fix filter-icon">filter_list</mat-icon>
            <input (keyup)="forwardFilter($event)" placeholder="Filter Value" class="filter skew-fix" type="text">
        </a>
    </div>
    <div class="button-container">
        <button *ngIf="!divLoaded" (click)="toggleDropDown()"><mat-icon>keyboard_arrow_down</mat-icon></button>
        <button *ngIf="divLoaded" (click)="toggleDropDown()"><mat-icon>keyboard_arrow_up</mat-icon></button>
    </div>
</div>


