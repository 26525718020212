<div class="outer-bound">
<div *ngIf="!category">
    <div class="flex-container">
        <div class="flex-item">
            <mat-card>
                <mat-card-content class="part-card">
                    <div class="card-div">
                        <span class="card-span">PartID:</span>
                        <span *ngIf="selectedPart.id !== 0" class="card-span">{{selectedPart.id}}</span>
                        <span *ngIf="selectedPart.id === 0" class="card-span">To be assigned</span>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item">
            <mat-form-field class="form-field-align" appearance="fill">
                <mat-label>Category</mat-label>
                <mat-select [(value)]="selectedPart.category">
                    <mat-option (click)="selectedPart.category_id = category.id"
                                *ngFor="let category of allCategorys" [value]="category.name">{{category.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">

                <mat-form-field class="form-field-align" hintLabel="Max 255 characters" appearance="fill">
                    <mat-label>Name</mat-label>
                    <input [(ngModel)]="selectedPart.name" name="name" matInput [value]="selectedPart.name" #name maxlength="255">
                    <mat-hint align="end">{{name.value.length}}/255</mat-hint>
                </mat-form-field>

        </div>
        <div class="flex-item">

                <mat-form-field class="form-field-align" hintLabel="Max 255 characters" appearance="fill">
                    <mat-label>Description</mat-label>
                    <input [(ngModel)]="selectedPart.description" name="decription" matInput [value]="selectedPart.description" #descr maxlength="255">
                    <mat-hint align="end">{{descr.value.length}}/255</mat-hint>
                </mat-form-field>

        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">

                <mat-form-field class="form-field-align" appearance="fill">
                    <mat-label>StockLevel</mat-label>
                    <input [(ngModel)]="selectedPart.stockLevel" type="number" name="stockLevel" matInput>
                </mat-form-field>

        </div>
        <div class="flex-item">
            <mat-form-field class="form-field-align" appearance="fill">
                <mat-label>Location</mat-label>
                <mat-select [(value)]="selectedPart.location">
                    <mat-option (click)="selectedPart.storageLocation_id = location.id"
                                *ngFor="let location of allStorageLocations" [value]="location.name">{{location.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item">
            <div class="bottom-button-box half">
                <button *ngIf="selectedPart.id !== 0" (click)="updatePart()" class="update-button" mat-button>Update Part</button>
                <button *ngIf="selectedPart.id === 0" (click)="initPart()" class="update-button" mat-button>Create Part</button>
            </div>
        </div>
        <div class="flex-item">
            <button style="float: right" (click)="deletePart(selectedPart.id)" class="update-button" mat-button>Delete Part</button>
        </div>
    </div>
</div>
</div>


<div class="outer-bound">
<div *ngIf="category">
    <div class="flex-container-column">
        <div class="flex-item-column">
            <mat-card>
                <mat-card-content class="part-card">
                    <div class="card-div">
                        <span class="card-span">Category Path:</span>
                        <span class="card-span">{{categoryPath}}</span>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item-column">
            <mat-form-field class="form-field-align-full" appearance="fill">
                <mat-label>CategoryName</mat-label>
                <input [(ngModel)]="newCategory" type="text" name="category" #category matInput>
            </mat-form-field>
        </div>
            <div class="flex-item-column">
                <button [disabled]="category.value === ''" (click)="initCategory()" class="update-button" mat-button>Create Category</button>
            </div>
    </div>
</div>
</div>