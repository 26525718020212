import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataSharingService} from "../services/data-sharing.service";
import {AccountApiGetService} from "../services/api/account-api-get.service";
import {
  Account,
  AccountComplete,
  AccountHolder,
  AccountStatus, AccountType,
  Application,
  Server,
  Website,
  OperatingSystem, ApplicationType, SharedAccount
} from "../models/account-models";
import {InterfaceTranslationService} from "../services/interface-translation.service";
import {
  defaultServer,
  defaultWebsite,
  defaultApplication,
  defaultAccount,
  defaultAccountComplete,
  defaultAccountType,
  defaultAccountStatus,
  defaultOperatingSystem,
  defaultApplicationType
} from "./default-functions";
import {AccountApiPostService} from "../services/api/account-api-post.service";
import {AccountApiDeleteService} from "../services/api/account-api-delete.service";
import {SnackbarService} from "../../auxiliarys/services/snackbar.service";
import {ValidationService} from "../../auxiliarys/services/validation.service";
import { FormControl } from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, Observable, startWith} from 'rxjs';
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {config} from "../config";
import {checkAccountInput, checkApplicationInput, checkServerInput, checkWebsiteInput} from "./check-functions";


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})


export class UserEditComponent implements OnInit {
  panelOpenState = true;
  accountID = 0;
  allServers: Server[] = [];
  allApplications: Application[] = [];
  allApplicationTypes: ApplicationType[] = []
  allWebsites: Website[] = [];
  allAccountHolders: AccountHolder[] = [];
  allAccountStatus: AccountStatus[] = [];
  allAccountTypes: AccountType[] = [];
  allOperatingSystems: OperatingSystem[] = [];
  cachedAccounts: AccountComplete[] = [];
  selectedAccount = <AccountComplete>{};
  selectedServer: Server = defaultServer();
  selectedWebsite: Website = defaultWebsite();
  selectedApplication: Application = defaultApplication();
  wasSelected = false;
  newServer!: Server;
  newWebsite!: Website;
  newApplication!: Application;
  newAccount!: Account;
  accountComplete!: AccountComplete;
  updateServerEntity: Server = defaultServer();
  updateWebsiteEntity: Website = defaultWebsite();
  updateApplicationEntity: Application = defaultApplication();
  selectedUpdateServerName = "";
  selectedUpdateWebsiteName = "";
  selectedUpdateApplicationName = "";
  selectedAccountStatusAdd: AccountStatus = defaultAccountStatus();
  selectedAccountStatusDelete: AccountStatus = defaultAccountStatus();
  selectedAccountTypeAdd: AccountType = defaultAccountType();
  selectedAccountTypeDelete: AccountType = defaultAccountType();
  selectedApplicationTypeAdd: ApplicationType = defaultApplicationType();
  selectedApplicationTypeDelete: ApplicationType = defaultApplicationType();
  selectedOperatingSystemAdd: OperatingSystem = defaultOperatingSystem();
  selectedOperatingSystemDelete: OperatingSystem = defaultOperatingSystem();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formControl = new FormControl('');
  filteredSharedAccountHolder!: Observable<string[]>;
  sharedAccountHolders: string[] = [];
  allAccountHolderIdentifier: string[] = [];
  @ViewChild('sharedAccountInput') sharedAccountInput!: ElementRef<HTMLInputElement>;
  public addAccountBooleanMapper: boolean[] = [false, false, false];
  public editAccountBooleanMapper: boolean[] = [false, false, false];
  public config = config;

  constructor(
      private dataSharingService: DataSharingService,
      private accountGetAPI: AccountApiGetService,
      private accountPostAPI: AccountApiPostService,
      private accountDeleteAPI: AccountApiDeleteService,
      private translator: InterfaceTranslationService,
      private snackbar: SnackbarService,
      public validationService: ValidationService
  ) {
    this.newServer = defaultServer();
    this.newWebsite = defaultWebsite();
    this.newApplication = defaultApplication();
    this.newAccount = defaultAccount();
    this.accountComplete = defaultAccountComplete();
  }


  ngOnInit(): void {
    this.dataSharingService.currentAccountID.subscribe(id => {
      this.accountID = id;
      this.loadServers();
      this.loadWebsites();
      this.loadApplications();
      this.accountGetAPI.getAllApplicationsTypes().subscribe(apiResponse => {
        this.allApplicationTypes = apiResponse as ApplicationType[];
      });
      this.accountGetAPI.getAllAccountHolder().subscribe(apiResponse => {
        this.allAccountHolders = apiResponse as AccountHolder[];

        this.allAccountHolders.forEach((holder) => {
          if (holder.Identifier !== '') {
            this.allAccountHolderIdentifier.push(holder.Identifier);
          }
        });
        this.filteredSharedAccountHolder = this.formControl.valueChanges.pipe(
            startWith(null),
            map((sharedAccount: string | null) =>
                (sharedAccount ? this._filter(sharedAccount) : this.allAccountHolderIdentifier.slice())),
        );
      });
      this.accountGetAPI.getAllAccountStatus().subscribe(apiResponse => {
        this.allAccountStatus = apiResponse as AccountStatus[];
      });
      this.accountGetAPI.getAllAccountTypes().subscribe(apiResponse => {
        this.allAccountTypes = apiResponse as AccountType[];
      });
      this.accountGetAPI.getAllOperatingSystems().subscribe(apiResponse => {
        this.allOperatingSystems = apiResponse as OperatingSystem[];
      });
      this.dataSharingService.wasSelected.subscribe(selected => {
        this.wasSelected = selected
      });
    });
    this.dataSharingService.cachedAccounts.subscribe((accounts => {
      this.cachedAccounts = accounts;
    }));
    this.dataSharingService.selectedAccount.subscribe((selected => {
      this.selectedAccount = selected;
    }));
  }

  public toogleBooleanMapper(senderIndex: number, mapper: Array<boolean>, value: string): void {
    if (value === '') {
      for (let i = 0; i < mapper.length; i++) {
        mapper[i] = false;
      }
      return;
    }
    for (let i = 0; i < mapper.length; i++) {
      mapper[i] = i !== senderIndex;
    }
  }

  sharedAccountAdd(event: MatChipInputEvent): void {
    const identifier = (event.value || '').trim();
    if (identifier) {
      this.sharedAccountHolders.push(identifier);
    }
    event.chipInput?.clear();
    this.formControl.setValue(null);
  }

  sharedAccountRemove(identifier: string): void {
    const index = this.sharedAccountHolders.indexOf(identifier);

    if (index >= 0) {
      this.sharedAccountHolders.splice(index, 1);
    }
  }

  sharedAccountSelected(event: MatAutocompleteSelectedEvent): void {
    const identifier = event.option.viewValue;
    if (this.sharedAccountHolders.indexOf(identifier) == -1) {
      this.sharedAccountHolders.push(identifier);
    }
    this.sharedAccountInput.nativeElement.value = '';
    this.formControl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allAccountHolderIdentifier.filter(filterArray => filterArray.toLowerCase().includes(filterValue));
  }


  // Loading functions for reloading the specific entity when changes where made

  private loadServers(): void {
    this.accountGetAPI.getAllServers().subscribe(apiResponse => {
      this.allServers = apiResponse as Server[];
    });
  }

  public loadUpdateServer(serverName: string): void {
    this.allServers.forEach((server) => {
      if (server.ServerName === serverName) {
        this.updateServerEntity = server;
        this.selectedUpdateServerName = this.updateServerEntity.ServerName;
      }
    });
  }

  private loadWebsites(): void {
    this.accountGetAPI.getAllWebsites().subscribe(apiResponse => {
      this.allWebsites = apiResponse as Website[];
    });
  }

  public loadUpdateWebsite(websiteName: string): void {
    this.allWebsites.forEach((website) => {
      if (website.WebsiteName === websiteName) {
        this.updateWebsiteEntity = website;
        this.selectedUpdateWebsiteName = this.updateWebsiteEntity.WebsiteName;
      }
    });
  }

  private loadApplications(): void {
    this.accountGetAPI.getAllApplications().subscribe(apiResponse => {
      this.allApplications = apiResponse as Application[];
    });
  }

  public loadUpdateApplication(applicationName: string): void {
    this.allApplications.forEach((application) => {
      if (application.ApplicationName === applicationName) {
        this.updateApplicationEntity = application;
        this.selectedUpdateApplicationName = this.updateApplicationEntity.ApplicationName;
      }
    });
  }

  private loadAccountStatus(): void {
    this.accountGetAPI.getAllAccountStatus().subscribe(apiResponse => {
      this.allAccountStatus = apiResponse as AccountStatus[];
    });
  }

  private loadAccountTypes(): void {
    this.accountGetAPI.getAllAccountTypes().subscribe(apiResponse => {
      this.allAccountTypes = apiResponse as AccountType[];
    });
  }

  private loadApplicationTypes(): void {
    this.accountGetAPI.getAllApplicationsTypes().subscribe(apiResponse => {
      this.allApplicationTypes = apiResponse as ApplicationType[];
    });
  }

  private loadOperatingsystems(): void {
    this.accountGetAPI.getAllOperatingSystems().subscribe(apiResponse => {
      this.allOperatingSystems = apiResponse as OperatingSystem[];
    });
  }


  // Delete functions for entitys

  public deleteServer(): void {
    this.snackbar.handleServerResonseCode(
        this.accountDeleteAPI.deleteServer(
            this.translator.translateServerID(this.allServers, this.selectedServer.ServerName)
        ));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedServer = defaultServer();
        this.loadServers();
      }
    });
  }

  public deleteWebsite(): void {
    this.snackbar.handleServerResonseCode(
        this.accountDeleteAPI.deleteWebsite(
            this.translator.translateWebsiteID(this.allWebsites, this.selectedWebsite.WebsiteName)
        ));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedWebsite = defaultWebsite();
        this.loadWebsites();
      }
    });
  }

  public deleteApplication(): void {
    this.snackbar.handleServerResonseCode(
        this.accountDeleteAPI.deleteApplication(
            this.translator.translateApplicationID(this.allApplications, this.selectedApplication.ApplicationName)
        ));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedApplication = defaultApplication();
        this.loadApplications();
      }
    });
  }

  public deleteAccountStatus(): void {
    this.snackbar.handleServerResonseCode(
        this.accountDeleteAPI.deleteAccountStatus(
            this.translator.translateAccountStatusID(this.allAccountStatus, this.selectedAccountStatusDelete.AccountStatus)
        ));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedAccountStatusDelete = defaultAccountStatus();
        this.loadAccountStatus();
      }
    });
  }

  public deleteAccountType(): void {
    this.snackbar.handleServerResonseCode(
        this.accountDeleteAPI.deleteAccountType(
            this.translator.translateAccountTypeID(this.allAccountTypes, this.selectedAccountTypeDelete.AccountType)
        ));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedAccountTypeDelete = defaultAccountType();
        this.loadAccountTypes();
      }
    });
  }

  public deleteApplicationType(): void {
    this.snackbar.handleServerResonseCode(
        this.accountDeleteAPI.deleteApplicationType(
            this.translator.translateApplicationTypeID(this.allApplicationTypes, this.selectedApplicationTypeDelete.ApplicationType)
        ));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedApplicationTypeDelete = defaultApplicationType();
        this.loadApplicationTypes();
      }
    });
  }

  public deleteOperatingSystem(): void {
    this.snackbar.handleServerResonseCode(
        this.accountDeleteAPI.deleteOperatingsystem(
            this.translator.translateOSID(this.allOperatingSystems, this.selectedOperatingSystemDelete.Name)
        ));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedOperatingSystemDelete = defaultOperatingSystem();
        this.loadOperatingsystems();
      }
    });
  }

  // Creation functions for entitys

  public initAccount(type: string): void {
    if (type === config.sharedAccount) {
      this.initSharedAccount();
      return;
    }
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initAccount(this.inputToAccount()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.accountComplete = defaultAccountComplete();
      }
    });
  }

  private initSharedAccount(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initSharedAccount(this.inputToSharedAccount()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.accountComplete = defaultAccountComplete();
      }
    });
  }

  public initServer(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initServer(this.inputToServer()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.newServer = defaultServer();
        this.loadServers();
      }
    });
  }

  public initWebsite(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initWebsite(this.inputToWebsite()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.newWebsite = defaultWebsite();
        this.loadWebsites();
      }
    });
  }

  public initApplication(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initApplication(this.inputToApplication()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.newApplication = defaultApplication();
        this.loadApplications();
      }
    });
  }

  public initAccountStatus(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initAccountStatus(this.inputToAccountStatus()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedAccountStatusAdd = defaultAccountStatus();
        this.loadAccountStatus();
      }
    });
  }

  public initAccountType(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initAccountType(this.inputToAccountType()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedAccountTypeAdd = defaultAccountType();
        this.loadAccountTypes();
      }
    });
  }

  public initApplicationType(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initApplicationType(this.inputToApplicationType()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedApplicationTypeAdd = defaultApplicationType();
        this.loadApplicationTypes();
      }
    });
  }

  public initOperatingsystem(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initOperatingSystem(this.inputToOperatingSystem()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.selectedOperatingSystemAdd = defaultOperatingSystem();
        this.loadOperatingsystems();
      }
    });
  }

  // Assemble entitys from userinput

  private inputToAccount(): Account {
    return {
      AccountID: 0,
      Username: this.accountComplete.Username,
      FK_AccountHolderID: this.translator.translateAccountHolderID(
          this.allAccountHolders, this.accountComplete.AccountHolder),
      FK_ServerID: this.translator.translateServerID(
          this.allServers, this.accountComplete.Server.ServerName),
      FK_WebsiteID: this.translator.translateWebsiteID(
          this.allWebsites, this.accountComplete.Website.WebsiteName),
      FK_ApplicationID: this.translator.translateApplicationID(
          this.allApplications, this.accountComplete.Application.ApplicationName),
      FK_AccountStatusID: this.translator.translateAccountStatusID(
          this.allAccountStatus, this.accountComplete.AccountStatus.AccountStatus),
      FK_AccountTypeID: this.translator.translateAccountTypeID(
          this.allAccountTypes, this.accountComplete.AccountType.AccountType)
    }
  }

  private inputToSharedAccount(): SharedAccount {
    const sharedAccountHolders: number[] = [];
    this.allAccountHolderIdentifier.forEach(accHolder => {
      sharedAccountHolders.push(
          this.translator.translateAccountHolderID(this.allAccountHolders, accHolder)
      )
    })
    return {
      AccountID: 0,
      Username: this.accountComplete.Username,
      FK_AccountHolderID: sharedAccountHolders,
      FK_ServerID: this.translator.translateServerID(
          this.allServers, this.accountComplete.Server.ServerName),
      FK_WebsiteID: this.translator.translateWebsiteID(
          this.allWebsites, this.accountComplete.Website.WebsiteName),
      FK_ApplicationID: this.translator.translateApplicationID(
          this.allApplications, this.accountComplete.Application.ApplicationName),
      FK_AccountStatusID: this.translator.translateAccountStatusID(
          this.allAccountStatus, this.accountComplete.AccountStatus.AccountStatus),
      FK_AccountTypeID: this.translator.translateAccountTypeID(
          this.allAccountTypes, this.accountComplete.AccountType.AccountType)
    }
  }

  private inputToServer(): Server {
    return {
      ServerID: 0,
      ServerName: this.newServer.ServerName,
      ServerIP: this.newServer.ServerIP,
      OS: {
        OSID: this.translator.translateOSID(this.allOperatingSystems,
            this.newServer.OS.Name),
        Name: this.newServer.OS.Name
      }
    };
  }

  private inputToUpdateServer(): Server {
    return {
      ServerID: this.translator.translateServerID(this.allServers,
          this.updateServerEntity.ServerName),
      ServerName: this.selectedUpdateServerName,
      ServerIP: this.updateServerEntity.ServerIP,
      OS: {
        OSID: this.translator.translateOSID(this.allOperatingSystems,
            this.updateServerEntity.OS.Name),
        Name: this.updateServerEntity.OS.Name
      }
    };
  }

  private inputToWebsite(): Website {
    return {
      WebsiteID: 0,
      WebsiteURL: this.newWebsite.WebsiteURL,
      WebsiteName: this.newWebsite.WebsiteName
    };
  }

  private inputToUpdateWebsite(): Website {
    return {
      WebsiteID: this.translator.translateWebsiteID(this.allWebsites,
          this.updateWebsiteEntity.WebsiteName),
      WebsiteURL: this.updateWebsiteEntity.WebsiteURL,
      WebsiteName: this.selectedUpdateWebsiteName
    };
  }

  private inputToApplication(): Application {
    return {
      ApplicationID: 0,
      ApplicationName: this.newApplication.ApplicationName,
      ApplicationType: {
        ApplicationTypeID: this.translator.translateApplicationTypeID(this.allApplicationTypes,
            this.newApplication.ApplicationType.ApplicationType),
        ApplicationType: this.newApplication.ApplicationType.ApplicationType
      }
    };
  }

  private inputToUpdateApplication(): Application {
    return {
      ApplicationID: this.translator.translateApplicationID(this.allApplications,
          this.updateApplicationEntity.ApplicationName),
      ApplicationName: this.selectedUpdateApplicationName,
      ApplicationType: {
        ApplicationTypeID: this.translator.translateApplicationTypeID(this.allApplicationTypes,
            this.updateApplicationEntity.ApplicationType.ApplicationType),
        ApplicationType: this.updateApplicationEntity.ApplicationType.ApplicationType
      }
    };
  }

  private inputToAccountStatus(): AccountStatus {
    return {
      AccountStatusID: 0,
      AccountStatus: this.selectedAccountStatusAdd.AccountStatus
    }
  }


  private inputToAccountType(): AccountType {
    return {
      AccountTypeID: 0,
      AccountType: this.selectedAccountTypeAdd.AccountType
    }
  }

  private inputToApplicationType(): ApplicationType {
    return {
      ApplicationTypeID: 0,
      ApplicationType: this.selectedApplicationTypeAdd.ApplicationType
    }
  }

  private inputToOperatingSystem(): OperatingSystem {
    return {
      OSID: 0,
      Name: this.selectedOperatingSystemAdd.Name
    }
  }

  // Update functions for entitys

  public updateAccount(): void {
    const account = {} as Account;
    account.AccountID = this.selectedAccount.AccountID;
    account.FK_AccountHolderID = this.translator.translateAccountHolderID(
        this.allAccountHolders, this.selectedAccount.AccountHolder);
    account.FK_AccountTypeID = this.translator.translateAccountTypeID(
        this.allAccountTypes, this.selectedAccount.AccountType.AccountType);
    account.FK_AccountStatusID = this.translator.translateAccountStatusID(
        this.allAccountStatus, this.selectedAccount.AccountStatus.AccountStatus);
    account.Username = this.selectedAccount.Username;
    account.FK_WebsiteID = this.translator.translateWebsiteID(
        this.allWebsites, this.selectedAccount.Website.WebsiteName);
    account.FK_ServerID = this.translator.translateServerID(
        this.allServers, this.selectedAccount.Server.ServerName);
    account.FK_ApplicationID = this.translator.translateApplicationID(
        this.allApplications, this.selectedAccount.Application.ApplicationName);
    if (this.wasSelected) {
      this.snackbar.handleServerResonseCode(this.accountPostAPI.updateAccount(account));
      this.dataSharingService.wasSelectedSource.next(false);
    }
  }

  public updateUpdateServer(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.updateServer(this.inputToUpdateServer()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.updateServerEntity = defaultServer();
        this.selectedUpdateServerName = "";
        this.loadServers();
      }
    });
  }

  public updateUpdateWebsite(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.updateWebsite(this.inputToUpdateWebsite()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.updateWebsiteEntity = defaultWebsite();
        this.selectedUpdateWebsiteName = "";
        this.loadWebsites();
      }
    });
  }

  public updateUpdateApplication(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.updateApplication(this.inputToUpdateApplication()));
    this.snackbar.responseSuccess.subscribe(state => {
      if (state) {
        this.updateApplicationEntity = defaultApplication();
        this.selectedUpdateApplicationName = "";
        this.loadApplications();
      }
    });
  }

  //Wrapped Check functions from external file

  public checkAccountInput(accountComplete: AccountComplete, value: string, sharedAccountHolders: string[]): boolean {
    return checkAccountInput(accountComplete, value, sharedAccountHolders);
  }

  public checkServerInput(entity: Server): boolean {
    return checkServerInput(entity);
  }

  public checkWebsiteInput(entity: Website): boolean {
    return checkWebsiteInput(entity);
  }

  public checkApplicationInput(entity: Application): boolean {
    return checkApplicationInput(entity);
  }
}

