import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-infra',
  templateUrl: './ai-infra.component.html',
  styleUrls: ['./ai-infra.component.scss']
})
export class AiInfraComponent {

}
