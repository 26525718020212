import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {
  defaultFlatNode,
  defaultPart,
  defaultPartCategorys,
  defaultParts, defaultStorageLocation, defaultStorageLocationCategorys,
  defaultStorageLocations
} from "../models/default-models";
import {FlatNode, Part, PartCategory, StorageLocation, StorageLocationCategory} from "../models/ai-parts-models";
import {AiPartsGetService} from "./api/ai-parts-get.service";


@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private selectedTabSource = new BehaviorSubject<number>(0);
  private selectedCategoryIDSource = new BehaviorSubject<number>(0);
  private selectedPartSource = new BehaviorSubject<Part>(defaultPart());
  private partsInCategorySource = new BehaviorSubject<Part[]>(defaultParts());
  private partCategorysSource = new BehaviorSubject<PartCategory[]>(defaultPartCategorys());
  private storageLocationsSource = new BehaviorSubject<StorageLocation[]>(defaultStorageLocations());
  private headCategoryNodeSource = new BehaviorSubject<FlatNode>(defaultFlatNode());
  private allPartCategorysSource = new BehaviorSubject<PartCategory[]>(defaultPartCategorys());
  private selectedCategorySource = new BehaviorSubject<string>('');
  private fullSearchBoolSource = new BehaviorSubject<boolean>(false);
  private selectedStorageLocationSource = new BehaviorSubject<StorageLocation>(defaultStorageLocation());
  private allStorageLocationCategorysSource = new BehaviorSubject<StorageLocationCategory[]>(defaultStorageLocationCategorys());

  public selectedTabObs = this.selectedTabSource.asObservable();
  public currentCategoryIDObs = this.selectedCategoryIDSource.asObservable();
  public partsInCategoryObs = this.partsInCategorySource.asObservable();
  public selectedPartObs = this.selectedPartSource.asObservable();
  public partCategorysObs = this.partCategorysSource.asObservable();
  public storageLocationsObs = this.storageLocationsSource.asObservable();
  public headCategoryNodeObs = this.headCategoryNodeSource.asObservable();
  public allPartCategorysObs = this.allPartCategorysSource.asObservable();
  public selectedCategoryObs = this.selectedCategorySource.asObservable();
  public fullSearchBoolObs = this.fullSearchBoolSource.asObservable();
  public selectedStorageLocationObs = this.selectedStorageLocationSource.asObservable();
  public allStorageLocationCategorysObs = this.allStorageLocationCategorysSource.asObservable();

  private selectedTab = 0;
  private currentCategoryID = 0;
  private partsInCategory: Part[] = defaultParts();
  private selectedPart: Part = defaultPart();
  private partCategorys: PartCategory[] = defaultPartCategorys();
  private storageLocations: StorageLocation [] = defaultStorageLocations();
  private headCategoryNode: FlatNode = defaultFlatNode();
  private selectedCategory = '';
  private fullSearchBool = false;
  private selectedStorageLocation: StorageLocation = defaultStorageLocation();
  private allStorageLocationCategorys: StorageLocationCategory[] = defaultStorageLocationCategorys();


  constructor(
      private aiPartsGetAPI: AiPartsGetService,
  ) {
    this.selectedTabObs.subscribe(tab => {
      this.selectedTab = tab;
    });
    this.selectedPartObs.subscribe(part => {
      this.selectedPart = part;
    });
    this.partsInCategoryObs.subscribe(parts => {
      this.partsInCategory = parts;
    });
    this.currentCategoryIDObs.subscribe(id => {
      this.currentCategoryID = id;
    });
    this.partCategorysObs.subscribe(cats => {
      this.partCategorys = cats;
    });
    this.storageLocationsObs.subscribe(locs => {
      this.storageLocations = locs
    });
    this.headCategoryNodeObs.subscribe(head => {
      this.headCategoryNode = head;
    });
    this.selectedCategoryObs.subscribe(cat => {
      this.selectedCategory = cat;
    });
    this.fullSearchBoolObs.subscribe( bool => {
      this.fullSearchBool = bool;
    });
    this.selectedStorageLocationObs.subscribe(loc => {
      this.selectedStorageLocation = loc;
    });
    this.allStorageLocationCategorysObs.subscribe(locCat => {
      this.allStorageLocationCategorys = locCat;
    });
  }

  public loadAllPartCategorys(): void {
    this.aiPartsGetAPI.getAllPartCategorys().subscribe(cats => {
      this.allPartCategorysSource.next(cats)
    });
  }

  public setSelectedTab(tab: number): void {
    this.selectedTabSource.next(tab);
  }

  public setSelectedCategoryID(id: number): void {
    this.selectedCategoryIDSource.next(id);
    this.setSelectedCategory(this.translateCategoryID(this.partCategorys, id));
  }

  public getSelectedCategoryID(): number {
    return this.currentCategoryID;
  }

  public setSelectedPart(part: Part): void {
    this.selectedPartSource.next(part);
  }

  public getSelectedPart(): Part {
    return this.selectedPart;
  }

  public setPartsInCategory(parts: Part[]): void {
    this.partsInCategorySource.next(parts);
  }

  public getPartsInCategory(): Part[] {
    return this.partsInCategory;
  }

  public setPartCategorys(cats: PartCategory[]): void {
    this.partCategorysSource.next(cats);
  }

  public getPartCategorys(): PartCategory[] {
    return this.partCategorys;
  }

  public setStorageLocations(locs: StorageLocation[]): void {
    this.storageLocationsSource.next(locs);
  }

  public setHeadCategoryNode(head: FlatNode): void {
    this.headCategoryNodeSource.next(head);
  }

  public getHeadCategoryNode(): FlatNode {
    return this.headCategoryNode;
  }

  public setSelectedCategory(category: string): void {
    this.selectedCategorySource.next(category);
  }

  public getSelectedCategory(): string {
    return this.selectedCategory;
  }

  public setFullSearchBool(bool: boolean): void {
    this.fullSearchBoolSource.next(bool);
  }

  public getFullSearchBool(): boolean {
    return this.fullSearchBool;
  }

  public setSelectedStorageLocation(loc: StorageLocation): void {
    this.selectedStorageLocationSource.next(loc);
  }

  public getSelectedStorageLocation(): StorageLocation {
    return this.selectedStorageLocation;
  }

  public setallStorageLocationCategorys(locCat: StorageLocationCategory[]): void {
    this.allStorageLocationCategorysSource.next(locCat);
  }

  public getallStorageLocationCategorys(): StorageLocationCategory[] {
    return this.allStorageLocationCategorys;
  }

  private translateCategoryID(array: PartCategory[], id: number): string {
    let candidate = '';
    array.forEach((cat) => {
      if(cat.id === id){
        candidate = cat.name;
      }
    });
    return candidate;
  }
}
