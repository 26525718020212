import {AccountComplete, Application, Server, Website} from "../models/account-models";
import {config} from "../config";

export function checkAccountInput(entity: AccountComplete, type: string, sharedAccountHolders: string[]): boolean {
    let accHolderValid = false;
    if(type == config.sharedAccount){
        if(sharedAccountHolders.length == 0){
            accHolderValid = true;
        }
        sharedAccountHolders.forEach(accHolder => {
            if(accHolder === ''){
                accHolderValid = true;
            }
        });
        return (
            entity.Username == '' ||
            accHolderValid ||
            entity.AccountType.AccountType == '' ||
            entity.AccountStatus.AccountStatus == '' ||
            (
                entity.Website.WebsiteName == '' &&
                entity.Server.ServerName == '' &&
                entity.Application.ApplicationName == ''
            )
        );
    }
    return (
        entity.Username == '' ||
        entity.AccountHolder == '' ||
        entity.AccountType.AccountType == '' ||
        entity.AccountStatus.AccountStatus == '' ||
        (
            entity.Website.WebsiteName == '' &&
            entity.Server.ServerName == '' &&
            entity.Application.ApplicationName == ''
        )
    );
}

export function checkServerInput(entity: Server): boolean {
    return (
        entity.OS.Name == '' ||
        entity.ServerName == '' ||
        entity.ServerIP == '');
}

export function checkWebsiteInput(entity: Website): boolean {
    return (
        entity.WebsiteName == '' ||
        entity.WebsiteURL == '');
}

export function checkApplicationInput(entity: Application): boolean {
    return (
        entity.ApplicationName == '' ||
        entity.ApplicationType.ApplicationType == '');
}