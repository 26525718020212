<div class="outer-bound">
<div *ngIf="selectedStorageLocation.id !== 0">
    <div class="flex-container-column">
        <div class="flex-item-column">
            <mat-card>
                <mat-card-content class="part-card">
                    <div class="card-div">
                        <span class="card-span">StorageLocationID:</span>
                        <span class="card-span">{{selectedStorageLocation.id}}</span>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item-column">
            <mat-form-field class="form-field-align-full" appearance="fill">
                <mat-label>Storage Location</mat-label>
                <input [(ngModel)]="selectedStorageLocation.name" type="text" name="storageLocation" #storageLocation matInput>
            </mat-form-field>
        </div>
        <div class="flex-item-column">
            <mat-form-field class="form-field-align" appearance="fill">
                <mat-label>Category</mat-label>
                <mat-select [value]="resolveStorageLocationCategoryID(selectedStorageLocation.category_id)">
                    <mat-option (click)="mapStorageLocationCategory_id(storageLocation.id)"
                                *ngFor="let storageLocation of allStorageLocationCategorys" [value]="storageLocation.name">{{storageLocation.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <div class="flex-item">
                <button [disabled]="storageLocation.value === ''" (click)="updateStorageLocation()" class="update-button" mat-button>Update Storage Location</button>
            </div>
            <div class="flex-item">
                <button style="float: right" [disabled]="selectedStorageLocation.id === 0" (click)="deleteStorageLocation()" class="update-button" mat-button>Delete Storage Location</button>
            </div>
        </div>
    </div>
</div>
</div>

<div class="outer-bound">
<div *ngIf="selectedStorageLocation.id === 0">
    <div class="flex-container-column">
        <div class="flex-item-column">
            <mat-card>
                <mat-card-content class="part-card">
                    <div class="card-div">
                        <span class="card-span">StorageLocationID:</span>
                        <span class="card-span">To be assigned</span>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item-column">
            <mat-form-field class="form-field-align-full" appearance="fill">
                <mat-label>Storage Location</mat-label>
                <input [(ngModel)]="newStorageLocationObj.name" type="text" name="newStorageLocation" #newStorageLocation matInput>
            </mat-form-field>
        </div>
        <div class="flex-item-column">
            <mat-form-field class="form-field-align" appearance="fill">
                <mat-label>Category</mat-label>
                <mat-select [value]="resolveStorageLocationCategoryID(newStorageLocationObj.category_id)">
                    <mat-option (click)="mapStorageLocationCategory_id(newStorageLocation.id)"
                                *ngFor="let newStorageLocation of allStorageLocationCategorys" [value]="newStorageLocation.name">{{newStorageLocation.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex-item-column">
            <button [disabled]="newStorageLocation.value === '' || newStorageLocationObj.category_id === 0" (click)="initStorageLocation()" class="update-button" mat-button>Create Storage Location</button>
        </div>

    </div>
</div>
</div>