import {
    Account,
    AccountComplete,
    AccountStatus,
    AccountType,
    Application, ApplicationType, OperatingSystem,
    Server,
    Website
} from "../models/account-models";
import {config} from "../config";

export function defaultServer(): Server {
    return {
        ServerID: 1,
        ServerName: "",
        ServerIP: "",
        OS: {
            OSID: 1,
            Name: ""
        }
    };
}
export function defaultWebsite(): Website {
    return {
        WebsiteID: 1,
        WebsiteURL: "",
        WebsiteName: ""
    };
}


export function defaultApplication(): Application {
    return {
        ApplicationID: 1,
        ApplicationName: "",
        ApplicationType: {
            ApplicationTypeID: 1,
            ApplicationType: ""
        }
    };
}

export function defaultAccount(): Account {
    return {
        AccountID: 1,
        FK_AccountHolderID: 1,
        FK_AccountStatusID: 1,
        FK_AccountTypeID: 1,
        FK_ApplicationID: 1,
        FK_ServerID: 1,
        FK_WebsiteID: 1,
        Username: ""
    }
}

export function defaultAccountComplete(): AccountComplete {
    return {
        AccountID: 0,
        AccountHolder: '',
        AccountType: {
            AccountTypeID: 1,
            AccountType: config.defaultEditAccountValues.accountType
        },
        AccountStatus: {
            AccountStatusID: 1,
            AccountStatus: config.defaultEditAccountValues.accountStatus
        },
        Username: '',
        Website: {
            WebsiteID: 1,
            WebsiteURL: '',
            WebsiteName: ''
        },
        Server: {
            ServerID: 1,
            ServerName: '',
            ServerIP: '',
            OS: {
                OSID: 1,
                Name: ""
            }},
        Application: {
            ApplicationID: 1,
            ApplicationName: '',
            ApplicationType: {
                ApplicationTypeID: 1,
                ApplicationType: ""
            }
        }
    }
}

export function defaultAccountStatus(): AccountStatus {
    return {
        AccountStatusID: 1,
        AccountStatus: ""
    }
}

export function defaultAccountType(): AccountType {
    return {
        AccountTypeID: 1,
        AccountType: ""
    }
}

export function defaultApplicationType(): ApplicationType {
    return {
        ApplicationTypeID: 1,
        ApplicationType: ""
    }
}

export function defaultOperatingSystem(): OperatingSystem {
    return {
        OSID: 1,
        Name: ""
    }
}