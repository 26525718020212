import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {OperatingSystem, Provider, Server, ServerStandort} from "../../models/ai-infra-models";
import {urlBaseAiInfra} from "../config";

@Injectable({
  providedIn: 'root'
})
export class AiInfraPostService {

  constructor(private http: HttpClient) {}

  public initServer(server: Server): Observable<HttpResponse<Server>>{
    return this.http.post<Server>(
        `${urlBaseAiInfra}/server/init`, JSON.stringify(server),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initOS(os: OperatingSystem): Observable<HttpResponse<OperatingSystem>>{
    return this.http.post<OperatingSystem>(
        `${urlBaseAiInfra}/operatingsystem/init`, JSON.stringify(os),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public updateOS(os: OperatingSystem): Observable<HttpResponse<OperatingSystem>>{
    return this.http.post<OperatingSystem>(
        `${urlBaseAiInfra}/operatingsystem/update`, JSON.stringify(os),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initProvider(provider: Provider): Observable<HttpResponse<Provider>>{
    return this.http.post<Provider>(
        `${urlBaseAiInfra}/provider/init`, JSON.stringify(provider),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public updateProvider(provider: Provider): Observable<HttpResponse<Provider>>{
    return this.http.post<Provider>(
        `${urlBaseAiInfra}/provider/update`, JSON.stringify(provider),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public initStandort(standort: ServerStandort): Observable<HttpResponse<ServerStandort>>{
    return this.http.post<ServerStandort>(
        `${urlBaseAiInfra}/serverstandort/init`, JSON.stringify(standort),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public updateStandort(standort: ServerStandort): Observable<HttpResponse<ServerStandort>>{
    return this.http.post<ServerStandort>(
        `${urlBaseAiInfra}/serverstandort/update`, JSON.stringify(standort),
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

}
