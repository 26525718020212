<div class="ai-parts-header">
    <mat-form-field style="width: 100%" class="filter">
        <mat-label>{{selectedCategory}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter Value" #input>
    </mat-form-field>
    <button (click)="openPartsForm(0)" mat-flat-button color="primary" class="add-part">
        <mat-icon style="width: 25px">library_add</mat-icon><span style="white-space: nowrap">Add Part</span>
    </button>
</div>

<div class="table-back">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name" sortActionDescription="Sort by Name">Name</th>
            <td mat-cell *matCellDef="let element">
                <button mat-button [matMenuTriggerFor]="menu">{{element.name}}</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openPartsForm(element.id)"><mat-icon>edit</mat-icon>Edit</button>
                    <button mat-menu-item [cdkCopyToClipboard]="element.name"><mat-icon>file_copy</mat-icon>Copy</button>
                </mat-menu>
                 </td>
        </ng-container>


        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="description" sortActionDescription="Sort by Description">Description</th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>


        <ng-container matColumnDef="stockLevel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="stockLevel" sortActionDescription="Sort by Stock">Stock</th>
            <td mat-cell *matCellDef="let element"> {{element.stockLevel}} </td>
        </ng-container>


        <ng-container matColumnDef="createDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createDate" sortActionDescription="Sort by Data">Creation</th>
            <td mat-cell *matCellDef="let element"> {{element.createDate}} </td>
        </ng-container>


        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="location" sortActionDescription="Sort by Location">Location</th>
            <td mat-cell *matCellDef="let element"> {{element.location}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

</div>
<div>

</div>
<div class="page-container">
    <div class="page-flex page-half">
        <mat-slide-toggle style="margin-left: 40px"
                class="example-margin"
                          [(ngModel)]="fullSearch"
                          (change)="updateFullSearch()"
        color="warn">
            FullSearch
        </mat-slide-toggle>
    </div>
    <div class="page-half">
        <mat-paginator aria-label="Select page of users"></mat-paginator>
    </div>
</div>

