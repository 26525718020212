import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AiInfraRoutingModule } from './ai-infra-routing.module';
import { ServerComponent } from './server/server.component';
import { WebsiteComponent } from './website/website.component';
import { SplitScreenComponent } from './split-screen/split-screen.component';
import {MatListModule} from "@angular/material/list";
import {MatTableModule} from "@angular/material/table";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatBadgeModule} from "@angular/material/badge";
import { TopDownMenuComponent } from './server/top-down-menu/top-down-menu.component';
import {MatIconModule} from "@angular/material/icon";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import { ServerBottomSheetComponent } from './server/server-bottom-sheet/server-bottom-sheet.component';
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import { ServerDetailComponent } from './server/server-detail/server-detail.component';
import {MatTabsModule} from "@angular/material/tabs";
import { ServerOverviewComponent } from './server/server-detail/overview/server-overview.component';
import { ServerGraphsComponent } from './server/server-detail/graphs/server-graphs.component';
import { ServerBackupsComponent } from './server/server-detail/backups/server-backups.component';
import { ServerLogsComponent } from './server/server-detail/logs/server-logs.component';


@NgModule({
  declarations: [
    ServerComponent,
    WebsiteComponent,
    SplitScreenComponent,
    TopDownMenuComponent,
    ServerBottomSheetComponent,
    ServerDetailComponent,
    ServerOverviewComponent,
    ServerGraphsComponent,
    ServerBackupsComponent,
    ServerLogsComponent
  ],
    imports: [
        CommonModule,
        AiInfraRoutingModule,
        MatListModule,
        MatTableModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatSortModule,
        MatBadgeModule,
        MatIconModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatSelectModule,
        FormsModule,
        MatButtonModule,
        MatMenuModule,
        MatTabsModule
    ]
})
export class AiInfraModule { }
