import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Part} from "../../models/ai-parts-models";
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {DataSharingService} from "../../services/data-sharing.service";
import {AiPartsGetService} from "../../services/api/ai-parts-get.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {PartsBottomSheetComponent} from "../parts-bottom-sheet/parts-bottom-sheet.component";
import {defaultPart, defaultParts} from "../../models/default-models";


@Component({
  selector: 'app-parts-table-view',
  templateUrl: './parts-table-view.component.html',
  styleUrls: ['./parts-table-view.component.scss']
})
export class PartsTableViewComponent implements AfterViewInit {
  data!: Part[];
  displayedColumns: string[] = ['name', 'description', 'stockLevel', 'createDate', 'location'];
  dataSource = new MatTableDataSource<Part>(this.data);
  partsInCategory: Part[] = [];
  selectedCategory = "";
  fullSearch = false;


  constructor(
      private _liveAnnouncer: LiveAnnouncer,
      private _bottomSheet: MatBottomSheet,
      public dataShare: DataSharingService,
      private aiPartsGetAPI: AiPartsGetService
  ) {}

  @ViewChild(MatSort) sort: any = MatSort;
  @ViewChild(MatPaginator) paginator:any = MatPaginator;

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.dataSource.paginator = this.paginator;
    setTimeout(() => {
      this.paginator.pageSize = 20;
    });
    this.dataSource.sort = this.sort;
    this.dataShare.selectedCategoryObs.subscribe(cat => {
      this.selectedCategory = cat;
    });
    this.dataShare.fullSearchBoolObs.subscribe(bool => {
      this.fullSearch = bool;
      if(this.fullSearch) {
        this.dataShare.setSelectedCategory('FullSearch');
        this.aiPartsGetAPI.getAllParts().subscribe(parts => {
          this.dataShare.setPartsInCategory(parts);
          this.dataSource.data = parts;
          this.partsInCategory = parts;
        })
      }
      else {
        this.dataShare.setSelectedCategory('');
        this.dataShare.setPartsInCategory(defaultParts());
      }
    });
    this.dataShare.currentCategoryIDObs.subscribe(id => {
      if(id) {
        this.aiPartsGetAPI.getAllPartsInCategorys(id).subscribe(parts => {
          this.dataShare.setPartsInCategory(parts);
          this.dataShare.partsInCategoryObs.subscribe(parts => {
            this.dataSource.data = parts;
            this.partsInCategory = parts;
          });
        });
      }
    });
  }

  public openPartsForm(id: number): void {
    if(id !== 0) {
      this.dataShare.setSelectedPart(this.getSeletedPart(id));
    }
    else {
      const part = defaultPart();
      part.category_id = this.dataShare.getSelectedCategoryID();
      part.category = this.getSeletedCategory(part.category_id);
      this.dataShare.setSelectedPart(part);
    }
    this._bottomSheet.open(
        PartsBottomSheetComponent,
        {
        panelClass: 'bottom-sheet-default'
        });
  }

  private getSeletedPart(id: number): Part {
    let matechedPart: Part = defaultPart();
    this.partsInCategory.forEach(part => {
      if(part.id === id){
        matechedPart = part;
      }
    });
    return matechedPart;
  }

  private getSeletedCategory(id: number): string {
    let matechedCategory = "";
    this.dataShare.getPartCategorys().forEach(cat => {
      if(cat.id === id){
        matechedCategory = cat.name;
      }
    });
    return matechedCategory;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public updateFullSearch() {
    this.dataShare.setFullSearchBool(this.fullSearch);
  }
}
