import { Injectable } from '@angular/core';
import {urlBase} from "./config";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AccountApiDeleteService {

  constructor(private http: HttpClient) {}

  public deleteAccount(accountID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBase}/account/delete/${accountID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteServer(serverID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBase}/server/delete/${serverID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteApplication(applicationID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBase}/application/delete/${applicationID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteWebsite(websiteID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBase}/website/delete/${websiteID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteAccountStatus(accountStatusID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBase}/accountstatus/delete/${accountStatusID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteAccountType(accountTypeID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBase}/accounttype/delete/${accountTypeID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteApplicationType(applicationTypeID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBase}/applicationtype/delete/${applicationTypeID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }

  public deleteOperatingsystem(osID: number): Observable<HttpResponse<number>>{
    return this.http.delete<number>(
        `${urlBase}/os/delete/${osID}`,
        { observe: 'response', headers: {'Content-Type': 'application/json'}});
  }
}
