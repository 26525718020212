import {Component, OnInit} from '@angular/core';
import {AccountApiGetService} from "../services/api/account-api-get.service";
import {AccountHolder} from "../models/account-models";
import {AccountApiPostService} from "../services/api/account-api-post.service";
import {SnackbarService} from "../../auxiliarys/services/snackbar.service";

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent implements OnInit{
  accountHolders!: AccountHolder[];
  expanded = false;
  newAccountHolder: AccountHolder = this.defaultAccountHolder()

  constructor(
      private accountPostAPI: AccountApiPostService,
      private accountAPI: AccountApiGetService,
      private snackbar: SnackbarService,
  ) {}


  private defaultAccountHolder(): AccountHolder {
    return {
      AccountHolderID: 0,
      Identifier: "",
      FirstName: "",
      FamilyName: ""
    }
  }

  public checkAccountHolderInput(entity: AccountHolder): boolean {
    return (
        entity.Identifier == '' ||
        entity.FirstName == '' ||
        entity.FamilyName == '');
  }

  private loadAccountHolder(): void {
    this.accountAPI.getAllAccountHolderNotZero().subscribe(apiResponse=> {
      this.accountHolders = apiResponse as AccountHolder[];
    });
  }

  public initAccountholder(): void {
    this.snackbar.handleServerResonseCode(
        this.accountPostAPI.initAccountHolder(this.inputToAccountHolder()));
    this.snackbar.responseSuccess.subscribe(state => {
      if(state){
        this.newAccountHolder = this.defaultAccountHolder();
        this.loadAccountHolder();
      }});
  }

  private inputToAccountHolder(): AccountHolder {
    return {
      AccountHolderID: 0,
      Identifier: this.newAccountHolder.Identifier,
      FirstName: this.newAccountHolder.FirstName,
      FamilyName: this.newAccountHolder.FamilyName
    }
  }

  ngOnInit(): void {
    this.loadAccountHolder();
  }

  public toogle(){
    this.expanded = !this.expanded;
  }

}
