import {Component, OnInit} from '@angular/core';
import {ExporterApiService} from "../../services/exporter-api.service";
import {Hardware} from "../models/exporter-models";
import {defaultHardware} from "../models/default-models";
import {DataSharingService} from "../../../services/data-sharing.service";
import {ValidationService} from "../../../../auxiliarys/services/validation.service";

@Component({
  selector: 'app-overview',
  templateUrl: './server-overview.component.html',
  styleUrls: ['./server-overview.component.scss']
})
export class ServerOverviewComponent implements OnInit{
  selectedServerHardware: Hardware = defaultHardware();

  constructor(
      private exporterApiService: ExporterApiService,
      private dataShare: DataSharingService,
      private validationService: ValidationService
  ) {
  }

  ngOnInit(): void {
    const apiURL = this.dataShare.getSelectedEditServer().API_URL;
    if(this.validationService.validateURL(apiURL)){
      this.exporterApiService.getServerHardwareInfo(apiURL).subscribe(_hw => {
        this.selectedServerHardware = _hw;
      });
    }

  }

}
