import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AiPartsRoutingModule } from './ai-parts-routing.module';
import { CategoryTreeComponent } from './category-tree/category-tree.component';
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { PartsTableViewComponent } from './part/parts-table-view/parts-table-view.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from "@angular/material/sort";
import {BrowserModule} from "@angular/platform-browser";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatMenuModule} from "@angular/material/menu";
import { PartsBottomSheetComponent } from './part/parts-bottom-sheet/parts-bottom-sheet.component';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {FormsModule} from "@angular/forms";
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ClipboardModule} from '@angular/cdk/clipboard';
import { LocationsBottomSheetComponent } from './location/locations-bottom-sheet/locations-bottom-sheet.component';
import { LocationsTableViewComponent } from './location/locations-table-view/locations-table-view.component';

@NgModule({
  declarations: [
    CategoryTreeComponent,
    PartsTableViewComponent,
    PartsBottomSheetComponent,
    LocationsBottomSheetComponent,
    LocationsTableViewComponent
  ],
    exports: [
        CategoryTreeComponent,
        PartsTableViewComponent,
        LocationsTableViewComponent
    ],
    imports: [
        CommonModule,
        AiPartsRoutingModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatSortModule,
        BrowserModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatMenuModule,
        MatBottomSheetModule,
        FormsModule,
        MatCardModule,
        MatSelectModule,
        MatSlideToggleModule,
        ClipboardModule
    ]
})
export class AiPartsModule { }
