import {Component, OnInit} from '@angular/core';
import {DataSharingService} from "./services/data-sharing.service";

@Component({
  selector: 'app-ai-parts',
  templateUrl: './ai-parts.component.html',
  styleUrls: ['./ai-parts.component.scss']
})
export class AiPartsComponent implements OnInit{
  selectedTab= 0;

  constructor(
      private dataShare: DataSharingService
  ) {}

  ngOnInit(): void {
    this.dataShare.selectedTabObs.subscribe(_tab => {
      this.selectedTab = _tab;
    });
  }
}
