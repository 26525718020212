import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {StorageLocation, StorageLocationCategory, StorageLocationResolved} from "../../models/ai-parts-models";
import {MatTableDataSource} from "@angular/material/table";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {DataSharingService} from "../../services/data-sharing.service";
import {AiPartsGetService} from "../../services/api/ai-parts-get.service";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {defaultStorageLocation} from "../../models/default-models";
import {LocationsBottomSheetComponent} from "../locations-bottom-sheet/locations-bottom-sheet.component";


@Component({
  selector: 'app-locations-table-view',
  templateUrl: './locations-table-view.component.html',
  styleUrls: ['./locations-table-view.component.scss']
})
export class LocationsTableViewComponent implements AfterViewInit{
  data!: StorageLocationResolved[];
  displayedColumns: string[] = ['name', 'category', 'id'];
  dataSource = new MatTableDataSource<StorageLocationResolved>(this.data);
  storageLocations: StorageLocation[] = [];
  storageLocationCategorys: StorageLocationCategory[] = [];
  fullSearch = false;

  constructor(
      private _liveAnnouncer: LiveAnnouncer,
      private _bottomSheet: MatBottomSheet,
      public dataShare: DataSharingService,
      private aiPartsGetAPI: AiPartsGetService
  ) {}

  @ViewChild(MatSort) sort: any = MatSort;
  @ViewChild(MatPaginator) paginator:any = MatPaginator;

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.dataSource.paginator = this.paginator;
    setTimeout(() => {
      this.paginator.pageSize = 20;
    });
    this.dataSource.sort = this.sort;
    this.aiPartsGetAPI.getAllStorageLocations().subscribe(locs => {
      this.aiPartsGetAPI.getAllStorageLocationCategorys().subscribe(cats => {
        this.storageLocationCategorys = cats;
        this.dataShare.setallStorageLocationCategorys(cats);
        this.storageLocations = locs;
          this.dataSource.data = this.resolveStorageCategoryID(locs);
      });
    });
  }

  private getSeletedStorageLocation(id: number): StorageLocation {
    let match = defaultStorageLocation();
    this.storageLocations.forEach(loc => {
      if(loc.id === id){
        match = loc;
      }
    });
    return match;
  }

  private resolveStorageCategoryID(locs: StorageLocation[]): StorageLocationResolved[] {
    const storageLocationResolved: StorageLocationResolved[] = [];
       locs.forEach(loc => {
         this.storageLocationCategorys.forEach(cats => {
           if(cats.id === loc.category_id) {
             const tmpLocation = {
               id: loc.id,
               category: cats.name,
               name: loc.name
             }
             storageLocationResolved.push(tmpLocation)
           }
         });
       });
    return storageLocationResolved;
  }

  public openLocationsForm(id: number): void {
    if(id !== 0){
      this.dataShare.setSelectedStorageLocation(this.getSeletedStorageLocation(id));
    }
    else {
      this.dataShare.setSelectedStorageLocation(defaultStorageLocation());
    }

    this._bottomSheet.open(
        LocationsBottomSheetComponent,
        {
          panelClass: 'bottom-sheet-default'
        });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
