import { Injectable } from '@angular/core';
import {SnackbarSuccessfullComponent} from "../snackbar-successfull/snackbar-successfull.component";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {SnackbarInfo} from "../snackbar-successfull/snackbar-info";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  public responseSuccess = new BehaviorSubject<boolean>(false);
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  durationInSeconds = 5;
  snackbarInfo: SnackbarInfo;

  constructor(
      private _snackBar: MatSnackBar
  ) {
    this.snackbarInfo = {
      Color: "white",
      Message: "Operation Failed",
      Success: false,
      Icon: "info",
      PanelClass: "app-notification-error"
    };
  }

  public handleServerResonseCode(response: Observable<HttpResponse<any>>): void {
    response.subscribe(resp => {
      switch (resp.status) {
        case 200:
          this.openSnackBar(0);
          this.responseSuccess.next(true);
          break;
        case 201:
          this.openSnackBar(0);
          this.responseSuccess.next(true);
          break;
        case 204:
          this.openSnackBar(2);
          this.responseSuccess.next(false);
          break;
        case 203:
          this.openSnackBar(3);
          this.responseSuccess.next(false);
          break;
        default:
          this.openSnackBar(1);
          this.responseSuccess.next(false);
          break;
      }
    });
  }

  public handleServerResonseCodeNoObs(status: number): void {
      switch (status) {
        case 200:
          this.openSnackBar(0);
          this.responseSuccess.next(true);
          break;
        case 201:
          this.openSnackBar(0);
          this.responseSuccess.next(true);
          break;
        case 204:
          this.openSnackBar(2);
          this.responseSuccess.next(false);
          break;
        case 203:
          this.openSnackBar(3);
          this.responseSuccess.next(false);
          break;
        default:
          this.openSnackBar(1);
          this.responseSuccess.next(false);
          break;
      }
  }

  public openSnackBar(state: number): void {
    switch (state) {
      case 0:
        this.snackbarInfo.Message = "Operation Successfull";
        this.snackbarInfo.Icon = "check_circle";
        this.snackbarInfo.PanelClass = "app-notification-success";
        break;
      case 1:
        this.snackbarInfo.Message = "Operation Failed";
        this.snackbarInfo.Icon = "info";
        this.snackbarInfo.PanelClass = "app-notification-error";
        break;
      case 2:
        this.snackbarInfo.Message = "API responded: No Change";
        this.snackbarInfo.Icon = "info";
        this.snackbarInfo.PanelClass = "app-notification-no-change";
        break;
      case 3:
        this.snackbarInfo.Message = "API responded: Access Denied";
        this.snackbarInfo.Icon = "info";
        this.snackbarInfo.PanelClass = "app-notification-error";
        break;
      default:
        this.snackbarInfo.Message = "Operation Failed";
        this.snackbarInfo.Icon = "info";
        this.snackbarInfo.PanelClass = "app-notification-error";
        break;
    }
    this._snackBar.openFromComponent(SnackbarSuccessfullComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds * 1000,
      data: this.snackbarInfo,
      panelClass: this.snackbarInfo.PanelClass,
    });
  }
}
