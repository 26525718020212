import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Part, PartCategory, StorageLocation, StorageLocationCategory} from "../../models/ai-parts-models";
import {urlBaseAiParts} from "./config";


@Injectable({
  providedIn: 'root'
})
export class AiPartsGetService {

  constructor(private http: HttpClient) { }

  // SELECT name, COUNT(*) FROM PartCategory GROUP BY name HAVING COUNT(*) > 1;
  public getPartCategoryByName(name: string): Observable<PartCategory> {
    return this.http.get<PartCategory>(
        `${urlBaseAiParts}/partcategory/get/${name}`);
  }

  public getAllPartCategorys(): Observable<PartCategory[]> {
    return this.http.get<PartCategory[]>(
        `${urlBaseAiParts}/partcategory/get/all`);
  }

  public getAllPartsInCategorys(id: number): Observable<Part[]> {
    return this.http.get<Part[]>(
        `${urlBaseAiParts}/part/get/parts-in/${id}`);
  }

  public getAllParts(): Observable<Part[]> {
    return this.http.get<Part[]>(
        `${urlBaseAiParts}/part/get/all`);
  }

  public getAllStorageLocations(): Observable<StorageLocation[]> {
    return this.http.get<StorageLocation[]>(
        `${urlBaseAiParts}/storagelocation/get/all`);
  }

  public getAllStorageLocationCategorys(): Observable<StorageLocationCategory[]> {
    return this.http.get<StorageLocationCategory[]>(
        `${urlBaseAiParts}/storagelocationcategory/get/all`);
  }
}